div[data-container='R_Element_InfoBetaalhistorie']{

    .appContent{
        
        .white-background{
            background:$color-accent-medium; 
            padding:15px; 
            border-bottom:2px solid $white;
            cursor:pointer;
            
            &:hover{
                background:$color-accent-light;
            }
            
            &.appSeparator{
                background:$color-accent-light; 
                font-weight:bold;
                
                >.small-12.columns.animate-show{
                    font-weight:normal;
                    padding-left:0; 
                    border-top:1px solid $color-accent-dark; 
                    padding-top:10px; 
                    margin-top:10px;
                }
            }
            
            &.appTotalSeparator{
                background:transparent;
                border-top:1px solid $color-accent-dark; 
                cursor:auto;
                
                &:hover{
                background:transparent;
            }
            
            }
        }
    } 
}

.infoBetaalhistorie{
    table tbody td{
        word-break: break-all;
    }

    div[id^="frmBetaalHistorie"]{
        font-weight:700;
    }
}
