@-webkit-keyframes loading {
  from {
    -webkit-transform: translateX(-100%);
    opacity: 0; }
  50% {
    opacity: 1; }
  to {
    -webkit-transform: translateX(-30%);
    opacity: 1; } 
}

@-moz-keyframes loading {
  from {
    -moz-transform: translateX(-100%);
    opacity: 0; }
  50% {
    opacity: 1; }
  to {
    -moz-transform: translateX(-30%);
    opacity: 1; } 
}

@keyframes loading {
  from {
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
    opacity: 0; }
  50% {
    opacity: 1; }
  to {
    -webkit-transform: translateX(-30%);
    -moz-transform: translateX(-30%);
    -ms-transform: translateX(-30%);
    -o-transform: translateX(-30%);
    transform: translateX(-30%);
    opacity: 1; } 
}

@-webkit-keyframes loading-done {
  from {
    -webkit-transform: translateX(-30%);
    opacity: 1; }
  50% {
    -webkit-transform: translateX(0);
    opacity: 1; }
  to {
    -webkit-transform: translateX(0);
    opacity: 0; } 
}

@-moz-keyframes loading-done {
  from {
    -moz-transform: translateX(-30%);
    opacity: 1; }
  50% {
    -moz-transform: translateX(0);
    opacity: 1; }
  to {
    -moz-transform: translateX(0);
    opacity: 0; } 
}

@keyframes loading-done {
  from {
    -webkit-transform: translateX(-30%);
    -moz-transform: translateX(-30%);
    -ms-transform: translateX(-30%);
    -o-transform: translateX(-30%);
    transform: translateX(-30%);
    opacity: 1; }
  50% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; }
  to {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    opacity: 0; } 
}

@-webkit-keyframes pulse-loader {
  from {
    opacity: 1; }
  50% {
    opacity: .65; }
  to {
    opacity: 1; } 
}

@-moz-keyframes pulse-loader {
  from {
    opacity: 1; }
  50% {
    opacity: .65; }
  to {
    opacity: 1; } 
}

@keyframes pulse-loader {
  from {
    opacity: 1; }
  50% {
    opacity: .65; }
  to {
    opacity: 1; } 
}


@keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
  }