.dg_contactgegevens{
    .appSubTitle{
        display: none;
    }
}


div[id^='frmControlerenContactgegevens']{
    
    button[id^="btnContinue"]{
        margin-top:35px; 
        }
    
    .pos-rel{
        margin-bottom:15px;
        margin-left:0 !important;
        
        .columns{
            padding:0;
        }
        
        .columns.small-10.medium-5.large-5 {
            width:80% !important;
        }
        
        .small-2.columns.no-margin {
            display:none;
        }
    }
    
    .padding-left-m{
        display:flex; 
        flex-direction:row;
        margin-bottom:15px;
    
        p{
            order:2;
        }
        
        .appLabel{
            display:block;
            width:15%; 
            position:relative;
            
            &:after{
                content:':';
            }
        }
    }
    
    .appFooter{
        display:flex; 
        flex-direction:row;
        
        .btn-primary{
            order:-1;
        }
    }
}



@include mobile{
    
    div[id^='frmControlerenContactgegevens']{
    
        button[id^="btnContinue"]{
            margin-top:15px; 
            }
    
        .pos-rel{
        
            .columns.small-10.medium-5.large-5 {
                width:90% !important;
            }
        }
        
        .padding-left-m{
             flex-direction:column;
        }
        
        .appFooter{
            flex-direction:column;
        }
    }
}




