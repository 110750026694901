.search-on-page {
    padding: 25px;
    background: $color-panel;
    .kb-search__input {
        background-color: $color-searchbar-footer;
    }
}

.dg_conclusion {
    .search-on-page {
        border-top: 2px solid $color-border-line;
        margin-top: 15px;
        padding-bottom: 0;
        background: transparent;
    }
}

#searchapp.hide+footer.margin-top {
    margin-top: 0 !important;
}