/* Styles added to fix the issue with zoom in on iphone /
/ iPhone < 5: */
@media screen and (device-aspect-ratio: 2/3) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"]{ font-size: 16px; }
    }
    
    /* iPhone 5, 5C, 5S, iPod Touch 5g */
    @media screen and (device-aspect-ratio: 40/71) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"]{ font-size: 16px; }
    }
    
    /* iPhone 6, iPhone 6s, iPhone 7 portrait/landscape */
    @media screen and (device-aspect-ratio: 375/667) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"]{ font-size: 16px; }
    }
    
    /* iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus portrait/landscape*/
    @media screen and (device-aspect-ratio: 9/16) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"]{ font-size: 16px; }
    }
    
    // iPhone X
    @media only screen and (device-width : 375px) and (device-height : 812px) and (-webkit-device-pixel-ratio : 3) {
        select, textarea, input[type="text"], input[type="password"],
        input[type="datetime"], input[type="datetime-local"],
        input[type="date"], input[type="month"], input[type="time"],
        input[type="week"], input[type="number"], input[type="email"],
        input[type="url"] { 
            font-size: 16px; 
        }
    }
    
    // iPhone 8
    @media only screen and (device-width : 375px) and (device-height : 667px) and (-webkit-device-pixel-ratio : 2) { 
        select, textarea, input[type="text"], input[type="password"],
        input[type="datetime"], input[type="datetime-local"],
        input[type="date"], input[type="month"], input[type="time"],
        input[type="week"], input[type="number"], input[type="email"],
        input[type="url"] { 
            font-size: 16px; 
        }
    }
    // iPhone 8 Plus
    @media only screen and (device-width : 414px) and (device-height : 736px) and (-webkit-device-pixel-ratio : 3) {
        select, textarea, input[type="text"], input[type="password"],
        input[type="datetime"], input[type="datetime-local"],
        input[type="date"], input[type="month"], input[type="time"],
        input[type="week"], input[type="number"], input[type="email"],
        input[type="url"] { 
            font-size: 16px; 
        }
    }
    
    // More references:
     /* iphone 3 */
    // @media only screen and (min-device-width: 320px) and (max-device-height: 480px) and (-webkit-device-pixel-ratio: 1) { }
    
    // /* iphone 4 */
    // @media only screen and (min-device-width: 320px) and (max-device-height: 480px) and (-webkit-device-pixel-ratio: 2) { }
    
    // /* iphone 5 */
    // @media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (-webkit-device-pixel-ratio: 2) { }
    
    // /* iphone 6, 6s, 7, 8 */
    // @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (-webkit-device-pixel-ratio: 2) { }
    
    // /* iphone 6+, 6s+, 7+, 8+ */
    // @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (-webkit-device-pixel-ratio: 3) { }
    
    // /* iphone X */
    // @media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (-webkit-device-pixel-ratio: 3) { }
    
    // /* iphone XR */
    // @media only screen and (min-device-width : 414px) and (max-device-height : 896px) and (-webkit-device-pixel-ratio : 2) { }
    
    // /* iphone XS */
    // @media only screen and (min-device-width : 375px) and (max-device-height : 812px) and (-webkit-device-pixel-ratio : 3) { }
    
    // /* iphone XS Max */
    // @media only screen and (min-device-width : 414px) and (max-device-height : 896px) and (-webkit-device-pixel-ratio : 3) { }