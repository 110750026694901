.project-filter {
    background: $white;
    border-radius: 25px 25px 0 0;
    margin-bottom: 25px !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    display: flex;
    &-column {
        padding: 15px;
    }
    &-border-left {
        border-left: 2px solid $color-border-line;
    }
    &-kop {
        padding-left: 0;
        margin: 20px 0;
    }
    &-item {
        @include button-primary;
        margin: 0 10px 10px 0;
        &.active {
            background: $color-secondary;
            color: $white !important;
        }
        &:hover {
            cursor: pointer;
            color: $color-secondary !important;
        }
    }
}

.project-filter-small {
    background: $color-panel;
    padding: 15px;
    margin: 15px 0;
    &-button {
        @include button-primary;
        position: relative;
        &:before {
            content: '\e20b';
            font-family: 'icomoon';
            position: absolute;
            right: 15px;
            top: 5px;
            font-size: 2rem;
        }
        &.collapse {
            &:after {
                content: 'Toon selectie';
                position: absolute;
                left: 0;
                right: 0;
                width: 100%;
                background: inherit;
            }
            &:before {
                content: '\e20a';
                z-index: 3;
            }
        }
    }
    .project-filter-item-small {
        @include button-secondary;
        &.active {
            background: $color-secondary;
            color: $white !important;
        }
        &:hover {
            cursor: pointer;
            color: $color-secondary !important;
        }
    }
    .project-filter-column-small {
        padding: 5px 15px;
        float: none !important;
    }
}

@include mobile {
    .project-filter {
        display: none;
    }
}