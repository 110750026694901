$colorIconBg: $white;
.category {
    display: flex;
    flex-wrap: wrap;
    margin-top: 100px !important;
    &__image {
        width: 140px;
        height: 140px;
        border-radius: 50%;
        background: $white;
        border: 5px solid $color-cta;
        margin-top: -70px;
        background-size: 75px !important;
        background-position: center !important;
        &.reparatie-en-onderhoud {
            background: $colorIconBg url(../../images/cat-reparatie.svg) no-repeat;
        }
        &.huis-en-contract {
            background: $colorIconBg url(../../images/cat-contract.svg) no-repeat;
        }
        &.huur-en-betalen {
            background: $colorIconBg url(../../images/cat-betalen.svg) no-repeat;
        }
        &.buren-en-omgeving {
            background: $colorIconBg url(../../images/cat-buren.svg) no-repeat;
        }
        &.ons-aanbod {
            background: $colorIconBg url(../../images/cat-aanbod.svg) no-repeat;
        }
        &.meer-weten-over {
            background: $colorIconBg url(../../images/cat-meer-weten.svg) no-repeat;
        }
        &.contact {
            background: $colorIconBg url(../../images/cat-contact.svg) no-repeat;
        }
        &.organisatie {
            background: $colorIconBg url(../../images/cat-organisatie.svg) no-repeat;
        }
        &.actueel {
            background: $colorIconBg url(../../images/cat-actueel.svg) no-repeat;
        }
    }
    &__title {
        margin: 15px 0;
        text-align: center;
        display: flex;
        align-items: center;
        min-height: 75px;
    }
    &__list {
        display: block;
        width: 100%;
        padding: 16px;
    }
    &__holder {
        display: flex;
        flex-direction: column;
        padding: 0;
        margin: 0 8px;
        background: $white;
        margin-bottom: 25px;
        align-items: center;
        &:first-of-type {
            border-radius: 25px 0 0 25px;
        }
        &:last-of-type {
            border-radius: 0 25px 25px 0;
        }
    }
    // columnwidth for 4 columnlayout
    &.kolom-4 {
        .category__holder {
            width: 22%;
            width: calc(25% - 16px);
        }
    }
    // columnwidth for 3 columnlayout
    &.kolom-3 {
        .category__holder {
            width: 30%;
            width: calc(33% - 16px);
        }
    }
    // columnwidth for 2 columnlayout
    &.kolom-2 {
        .category__holder {
            width: 47%;
            width: calc(50% - 16px);
        }
    }
}

@include tablet {
    .category {
        &__image {
            height: 130px;
            width: 130px;
            margin-top: -65px;
        }
        .category__holder {
            margin-bottom: 100px;
            &:first-of-type {
                border-radius: 25px 0 0 0;
            }
            &:nth-of-type(2) {
                border-radius: 0 25px 0 0;
            }
            &:last-of-type {
                border-radius: 0 0 25px 0;
            }
        }
        // columnwidth for 4 columnlayout
        &.kolom-4 {
            .category__holder {
                width: 47%;
                width: calc(50% - 16px);
            }
        }
        // columnwidth for 3 columnlayout
        &.kolom-3 {
            .category__holder {
                width: 30%;
                width: calc(33% - 16px);
            }
        }
        // columnwidth for 2 columnlayout
        &.kolom-2 {
            .category__holder {
                width: 47%;
                width: calc(50% - 16px);
            }
        }
    }
}

@include mobile {
    .category {
        margin-top: 50px !important;
        &__title {
            cursor: pointer;
            position: relative;
            padding: 10px;
            margin: 5px 0 0;
            min-height: 0;
        }
        &__image {
            width: 100px;
            height: 100px;
            margin-top: -50px;
            background-size: 50px !important;
            border-width: 3px;
        }
        &__list {
            height: auto;
        }
        .category__holder {
            margin: 0 15px 75px;
            width: calc(100% - 30px) !important;
            border-radius: 25px 25px 0 0;
            &:first-of-type {
                border-radius: 25px 25px 0 0;
            }
            &:last-of-type {
                border-radius: 25px 25px 0 0;
            }
            &:nth-of-type(2) {
                border-radius: 25px 25px 0 0;
            }
        }
        // columnwidth for 4 columnlayout
        &.kolom-4 {
            .category__holder {
                width: 100%;
            }
        }
        // columnwidth for 3 columnlayout
        &.kolom-3 {
            .category__holder {
                width: 100%;
            }
        }
        // columnwidth for 2 columnlayout
        &.kolom-2 {
            .category__holder {
                width: 100%;
            }
        }
    }
}