.stappenplan {
    &__stapwrapper {
        padding: 7px 0;
        &.firstStep {
            .stappenplan__stap {
                border-radius: 25px 25px 0 0;
            }
            .stappenplan__line {
                margin-top: 45px;
                height: 100%;
            }
        }
        &.lastStep {
            .stappenplan__stap {
                border-radius: 0 0 25px 25px;
            }
            .stappenplan__line {
                height: 45px;
            }
        }
    }
    &__stap {
        @include panel--default;
        padding: 0;
        position: relative;
    }
    &__stapnummer {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 1px solid $color-step-number;
        background: $color-step-numberbg;
        color: $color-step-number;
        position: absolute;
        top: 40px;
        left: 35px;
        z-index: 2;
        div {
            display: table;
            height: 100%;
            width: 100%;
            p {
                height: 100%;
                width: 100%;
                font-weight: 700;
                color: $color-step-number;
                text-align: center;
                vertical-align: middle;
                display: table-cell;
                font-size: 22px;
            }
        }
    }
    &__line {
        width: 1px;
        height: calc(100% + 45px);
        position: absolute;
        background: $color-cta;
        left: 55px;
        float: left;
    }
    &__stapcontent {
        padding: 45px 25px 25px 110px;
    }
}