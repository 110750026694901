.appContainer {
    @include appcontainer--default;
}

.appHeader {
    margin-bottom: 25px;
    .appTitle {
        color: $color-dg-question;
    }
    .appSubTitle {
        font-weight: 700;
    }
}

.appSubTitle {
    font-family: $webfont;
    font-weight: 700;
}

.appContent {
    .appListWhite {
        border-bottom: 1px solid $color-primary;
        padding: 10px 0px 15px;
        margin-bottom: 15px !important;
        font-weight: 700;
    }
    .appTotalSeparator {
        padding-top: 10px;
        border-top: 1px solid $color-border-line;
        margin-top: 15px !important;
        font-weight: 700;
        .appListTextBig {
            font-weight: 700;
        }
    }
    .hoverbuttons {
        background: $color-bg;
        padding: 15px 0;
        margin-top: 35px !important;
    }
}

.appChevronIcon i {
    font-size: 1.3rem;
}

.appListDark {
    .appListTextBig {
        font-weight: 700;
    }
}