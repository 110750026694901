.newsitem-builder-container .sidebar-builder {
    width: 360px;
}

.cke_inner span {
    color: inherit;
}

.env-admin .iconbutton--link {
    position: relative;
}

.env-admin .cta-btn--link {
    position: relative;
}

.page-builder-container .workspace footer {
    display: block !important;
}

.page-builder-container .online-search-container input {
    border: 1px solid $color-border-line !important;
    border-radius: $borderradius !important;
}

#contenteditor [class*="column"]+[class*="column"]:last-child,
#informationbankcontent [class*="column"]+[class*="column"]:last-child {
    float: left;
}

#contenteditor [class*="column"]+[class*="column"]:last-child:after,
#informationbankcontent [class*="column"]+[class*="column"]:last-child:after {
    clear: both;
}

#contenteditor {
    .htmlContent {
        padding: 15px;
    }
    p {
        margin-top: 15px;
        padding: 10px;
    }
}

.newsitem-builder-container .row.news-width {
    width: calc(100% - 450px);
}

#adminmain {
    .cta__wrapper {
        margin-top: 20px
    }
    .cta__btn--link {
        position: relative;
    }
    a {
        box-shadow: none !important;
    }
}

div[element-type="text"].cke_focus {
    width: 100% !important;
    min-width: 150px;
    display: block;
    padding: 10px;
}