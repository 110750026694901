#LoginContent {
    background: $color-panel;
}

.login {
    &__content {
        margin: 50px 0;
    }
    &__form {
        display: flex;
        padding-bottom: 25px;
        &--inlog {
            background: $white;
            padding: 25px;
            input {
                margin-bottom: 5px;
                padding-left: 35px;
            }
            .labelText {
                margin-top: 15px;
                margin-bottom: 5px;
            }
            .input-field .row {
                margin-left: 0;
                margin-right: 0;
            }
            .login__input--username {
                position: relative;
                &:before {
                    position: absolute;
                    content: '\e07c';
                    font-family: 'icomoon';
                    display: block;
                    left: 10px;
                    top: 10px;
                    width: 30px;
                    height: 100%;
                    color: $color-primary;
                    z-index: 1;
                }
            }
            .login__input--password {
                position: relative;
                &:before {
                    position: absolute;
                    content: "\e2ab";
                    font-family: 'icomoon';
                    display: block;
                    left: 10px;
                    top: 10px;
                    width: 30px;
                    height: 100%;
                    color: $color-primary;
                    z-index: 1;
                }
            }
            &-footer {
                padding: 35px 0 15px;
                .link-forgot-password {
                    float: right;
                    @include textlink;
                }
            }
        }
        &--account {
            @include appcontainer--default;
            padding: 25px;
            margin-left: 25px;
            margin-bottom: 0;
        }
    }
}

div#loginerror.error {
    margin-bottom: 25px;
    margin-top: 0;
    color: $color-invalid;
    text-align: left;
}

@include mobile {
    .login {
        &__form {
            display: block;
        }
        &__content {
            margin: $mobile-header-height 0 0 0;
        }
        &__form--account {
            margin-top: 15px;
            margin-left: 0;
        }
        &__form--inlog-footer {
            .link-forgot-password {
                float: none;
            }
        }
    }
}