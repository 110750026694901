[class^="adminIcon-"],
[class*=" adminIcon-"] {
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-feature-settings: 'liga';
    font-feature-settings: 'liga'
}
.adminIcon-file-o:before {
    content: "\f016"
}
.adminIcon-twitter-square:before {
    content: "\f081"
}
.adminIcon-facebook-square:before {
    content: "\f082"
}
.adminIcon-linkedin-square:before {
    content: "\f08c"
}
.adminIcon-twitter:before {
    content: "\f099"
}
.adminIcon-facebook:before {
    content: "\f09a"
}
.adminIcon-facebook-f:before {
    content: "\f09a"
}
.adminIcon-linkedin:before {
    content: "\f0e1"
}
.adminIcon-file-text-o:before {
    content: "\f0f6"
}
.adminIcon-youtube-square:before {
    content: "\f166"
}
.adminIcon-youtube:before {
    content: "\f167"
}
.adminIcon-youtube-play:before {
    content: "\f16a"
}
.adminIcon-instagram:before {
    content: "\f16d"
}
.adminIcon-file-pdf:before {
    content: "\f1c1"
}
.adminIcon-file-word:before {
    content: "\f1c2"
}
.adminIcon-file-excel:before {
    content: "\f1c3"
}
.adminIcon-file-powerpoint:before {
    content: "\f1c4"
}
.adminIcon-file-image:before {
    content: "\f1c5"
}
.adminIcon-file-photo:before {
    content: "\f1c5"
}
.adminIcon-file-picture:before {
    content: "\f1c5"
}
.adminIcon-flipvertical:before {
    content: "\e901"
}
.adminIcon-fliphorizontal:before {
    content: "\e902"
}
.adminIcon-new_case:before {
    content: "\e90b"
}
.adminIcon-new_message:before {
    content: "\e90c"
}
.adminIcon-decision-tree:before {
    content: "\e90a"
}
.adminIcon-website:before {
    content: "\e909"
}
.adminIcon-page:before {
    content: "\e908"
}
.adminIcon-donut_large:before {
    content: "\e905"
}
.adminIcon-donut_small:before {
    content: "\e906"
}
.adminIcon-timeline:before {
    content: "\e907"
}
.adminIcon-drag-handle:before {
    content: "\e904"
}
.adminIcon-update:before {
    content: "\e903"
}
.adminIcon-bling:before {
    content: "\e900"
}
.adminIcon-error:before {
    content: "\e000"
}
.adminIcon-error_outline:before {
    content: "\e001"
}
.adminIcon-warning:before {
    content: "\e002"
}
.adminIcon-equalizer:before {
    content: "\e007"
}
.adminIcon-explicit:before {
    content: "\e008"
}
.adminIcon-pause:before {
    content: "\e018"
}
.adminIcon-play_arrow:before {
    content: "\e01b"
}
.adminIcon-stop:before {
    content: "\e02a"
}
.adminIcon-web:before {
    content: "\e034"
}
.adminIcon-call_end:before {
    content: "\e040"
}
.adminIcon-chat:before {
    content: "\e046"
}
.adminIcon-chat_bubble:before {
    content: "\e054"
}
.adminIcon-add:before {
    content: "\e069"
}
.adminIcon-add_circle_outline:before {
    content: "\e06c"
}
.adminIcon-content_copy:before {
    content: "\e071"
}
.adminIcon-content_paste:before {
    content: "\e073"
}
.adminIcon-create:before {
    content: "\e074"
}
.adminIcon-link:before {
    content: "\e07b"
}
.adminIcon-mail:before {
    content: "\e07c"
}
.adminIcon-redo:before {
    content: "\e07e"
}
.adminIcon-remove:before {
    content: "\e07f"
}
.adminIcon-remove_circle_outline:before {
    content: "\e081"
}
.adminIcon-save:before {
    content: "\e085"
}
.adminIcon-send:before {
    content: "\e087"
}
.adminIcon-undo:before {
    content: "\e08a"
}
.adminIcon-access_time:before {
    content: "\e08e"
}
.adminIcon-signal_wifi_off:before {
    content: "\e0bb"
}
.adminIcon-attach_file:before {
    content: "\e0c0"
}
.adminIcon-format_align_left:before {
    content: "\e0d0"
}
.adminIcon-format_size:before {
    content: "\e0df"
}
.adminIcon-insert_chart:before {
    content: "\e0e5"
}
.adminIcon-insert_drive_file:before {
    content: "\e0e7"
}
.adminIcon-mode_edit:before {
    content: "\e0ee"
}
.adminIcon-file_upload:before {
    content: "\e100"
}
.adminIcon-folder:before {
    content: "\e101"
}
.adminIcon-folder_open:before {
    content: "\e102"
}
.adminIcon-desktop_mac:before {
    content: "\e107"
}
.adminIcon-smartphone:before {
    content: "\e127"
}
.adminIcon-tablet_mac:before {
    content: "\e12c"
}
.adminIcon-crop:before {
    content: "\e153"
}
.adminIcon-image:before {
    content: "\e186"
}
.adminIcon-photo_camera:before {
    content: "\e1a4"
}
.adminIcon-picture_as_pdf:before {
    content: "\e1a6"
}
.adminIcon-rotate_left:before {
    content: "\e1aa"
}
.adminIcon-rotate_right:before {
    content: "\e1ab"
}
.adminIcon-local_phone:before {
    content: "\e1e9"
}
.adminIcon-place:before {
    content: "\e1f7"
}
.adminIcon-apps:before {
    content: "\e1ff"
}
.adminIcon-arrow_back:before {
    content: "\e200"
}
.adminIcon-arrow_drop_down:before {
    content: "\e201"
}
.adminIcon-arrow_drop_up:before {
    content: "\e203"
}
.adminIcon-arrow_forward:before {
    content: "\e204"
}
.adminIcon-check:before {
    content: "\e206"
}
.adminIcon-chevron_left:before {
    content: "\e207"
}
.adminIcon-chevron_right:before {
    content: "\e208"
}
.adminIcon-close:before {
    content: "\e209"
}
.adminIcon-expand_less:before {
    content: "\e20a"
}
.adminIcon-expand_more:before {
    content: "\e20b"
}
.adminIcon-menu:before {
    content: "\e20e"
}
.adminIcon-keyboard_control:before {
    content: "\e20f"
}
.adminIcon-more_vert:before {
    content: "\e210"
}
.adminIcon-unfold_more:before {
    content: "\e213"
}
.adminIcon-phone_forwarded:before {
    content: "\e222"
}
.adminIcon-phone_in_talk:before {
    content: "\e223"
}
.adminIcon-phone_missed:before {
    content: "\e225"
}
.adminIcon-phone_paused:before {
    content: "\e226"
}
.adminIcon-sync:before {
    content: "\e22b"
}
.adminIcon-sync_problem:before {
    content: "\e22d"
}
.adminIcon-wifi:before {
    content: "\e242"
}
.adminIcon-group:before {
    content: "\e245"
}
.adminIcon-notifications:before {
    content: "\e24a"
}
.adminIcon-person:before {
    content: "\e253"
}
.adminIcon-person_add:before {
    content: "\e254"
}
.adminIcon-aspect_ratio:before {
    content: "\e270"
}
.adminIcon-autorenew:before {
    content: "\e278"
}
.adminIcon-chrome_reader_mode:before {
    content: "\e282"
}
.adminIcon-code:before {
    content: "\e284"
}
.adminIcon-delete:before {
    content: "\e287"
}
.adminIcon-history:before {
    content: "\e29d"
}
.adminIcon-home:before {
    content: "\e29e"
}
.adminIcon-info:before {
    content: "\e2a2"
}
.adminIcon-info_outline:before {
    content: "\e2a3"
}
.adminIcon-label:before {
    content: "\e2a6"
}
.adminIcon-label_outline:before {
    content: "\e2a7"
}
.adminIcon-list:before {
    content: "\e2aa"
}
.adminIcon-lock:before {
    content: "\e2ab"
}
.adminIcon-open_in_new:before {
    content: "\e2b2"
}
.adminIcon-open_with:before {
    content: "\e2b3"
}
.adminIcon-question_answer:before {
    content: "\e2c3"
}
.adminIcon-search:before {
    content: "\e2ca"
}
.adminIcon-settings:before {
    content: "\e2cb"
}
.adminIcon-today:before {
    content: "\e2f1"
}
.adminIcon-view_list:before {
    content: "\e301"
}
.adminIcon-view_module:before {
    content: "\e302"
}
.adminIcon-view_stream:before {
    content: "\e304"
}
.adminIcon-visibility:before {
    content: "\e306"
}
.adminIcon-visibility_off:before {
    content: "\e307"
}
.adminIcon-work:before {
    content: "\e30b"
}
.adminIcon-play_for_work:before {
    content: "\e317"
}