.error{
    
    &-pagetemplate{
        background:$color-bg;
    }
    
    &-header{
       background:$color-header-bg; 
    }
    
    &-footer{
        height:100px;
     }
  
    &-section{
        top: auto;
        text-align: center;
        width: 100%;
        margin: 0 auto;
        height:calc(100% - 100px);
        
            
        .panel{
            padding:35px;
            margin-top:50px;
                      
            a{
                @include textlink;
            }
        }
         
    }
}

@include mobile{
    .error{
        
        &-pagetemplate{
        
            .header .header-menu-item a{
                padding: 15px 20px; 
                text-align:center;
            }
            
            .client-logo{
            
                img{
                    height:80px;
                }
            }
        }
        
        &-section{
            height:auto;

            .client-logo img{
                width:auto;
                height:40px;
            }
            
            .panel{
                margin:15px;
                width: calc(100% - 30px);
            }
        }

        &-footer{
            height:auto;
        }
    }
}