.app-customer-timeline {
  &.appContainer {
    border: none;
    padding: 1rem 0;
  }
}

.app-customer-timeline-trace__step {

  &:before {
    background: $color-primary;
  }
}

.app-customer-timeline-trace__step-bullet {
  background-color: $color-primary;

  &.is-current {
    border: solid 4px $color-primary;
  }
}