.RVHistorie {
    .appContent {
        margin-bottom: 25px;
        background: $color-panel;
        &:hover {
            cursor: pointer;
        }
        &:focus {
            outline-color: none;
        }
        i {
            color: $color-primary;
        }
        .repatatie-omschrijving {
            font-weight: 700;
        }
        .appListDark {
            padding: 15px;
            background: $color-accent-medium;
            border-bottom: 2px solid $white;
            cursor: pointer;
            &:hover {
                background: $color-accent-light;
            }
            &.appSeparator {
                background: $color-accent-light;
                .small-10.columns.no-margin {
                    font-weight: bold;
                }
            }
        }
    }
    .reparatie-info {
        background: $color-bg;
        margin-top: 10px;
        .info-detail {
            border-top: 1px solid $color-border-line;
            padding: 10px;
        }
        .appLabel {
            color: $color-accent-dark;
            font-weight: 700;
        }
    }
}