.cookieinfobar {
    max-width: 350px;
    border-radius: 25px 0 0 0;
    @include box-shadow(2);
    display: flex;
    flex-direction: column;
    line-height: 24px;
    @include mobile {
        left: 25px !important;
        max-width: none;
    }
    .cookieinfo-close {
        width: 100px;
        margin: 0 0 0 auto;
        order: 2;
        padding: 8px 12px;
        border-radius: 25px;
        margin-top: 15px;
        color: $white !important;
        &:hover {
            background: $color-primary !important;
        }
    }
}