thead, tfoot{
    background:$color-bg;
}

thead, tbody, tfoot{
    border:none;
}

thead{
    border-bottom:1px solid $color-primary;

    a{
        color:$color-primary;

        span{
            color:$color-primary;
        }
    }
}
 tbody, tfoot{
    border-bottom:1px solid $color-border-line;
}

tbody tr:nth-child(even){
    background-color: $color-panel;
}

tbody{
    td{         
        vertical-align:top;
    } 
}

.selectedRow{
    background: $color-primary !important;
    color:$white;
}