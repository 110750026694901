#main {
    background: $color-bg;
    position: relative;
    display: flex;
    flex-direction: column;
}

main {
    flex: 1;
}

// IE hack for flexbox overwrite not to mess up the pagelayout 
@media screen and (min-width:0\0) {
    #main {
        display: block;
    }
}

// layout for iris content
#PageContent {
    padding-bottom: 0px;
    padding-top: 0px;
}

// layout for iris searchresults
#PanelContent>div {
    padding-top: 50px;
    padding-bottom: 50px;
}

.colorbg {
    background: $color-accent-medium;
    padding-bottom: 60px;
    margin-bottom: 60px;
}

#homepage .colorbg {
    margin-top: 50px;
    @include tablet {
        margin-top: 30px;
    }
    @include mobile {
        margin-top: 0;
    }
}

#categorypage,
#listpage {
    margin-bottom: 0;
}

.contentmain {
    p,
    img,
    .fotoboek,
    .video__holder,
    ul,
    ol,
    .stappenplan__stapwrapper.lastStep {
        margin-bottom: 50px;
        @include mobile {
            margin-bottom: 25px;
        }
    }
    p+ul {
        margin-top: -50px;
        @include mobile {
            margin-top: -25px;
        }
    }
}

.fullWidth {
    max-width: 100% !important;
    width: 100%;
    margin-left: auto !important;
    margin-right: auto !important;
    .row {
        max-width: 100% !important;
    }
    .columns {
        padding: 0 !important;
        .row {
            margin: 0 !important;
        }
    }
}

.row {
    max-width: 75rem;
}

.contentwrapper {
    .column:last-child:not(:first-child),
    .columns:last-child:not(:first-child) {
        float: left;
    }
}

.panel {
    @include panel--default;
    .list-menu-open li:hover {
        background: $white;
    }
}

.pagination-section {
    text-align: center;
}

@include tablet {
    .colorbg {
        padding-bottom: 60px;
        margin-bottom: 60px;
    }
}

@include mobile {
    .contentwrapper {
        margin-top: $mobile-header-height;
    }
    #PanelContent>div {
        padding-top: $mobile-header-height;
    }
    .colorbg {
        padding-bottom: 30px;
        margin-bottom: 30px;
    }
}