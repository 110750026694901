// media queries //
@mixin small-mobile {
    @media screen and (max-width: 480px) {
        @content;
    }
}

@mixin mobile {
    @media screen and (max-width: #{$max-mobile-width - 1px}) {
        @content;
    }
}

@mixin mobile-tablet {
    @media screen and (max-width: #{$max-tablet-width - 1px}) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: #{$max-mobile-width}) and (max-width: #{$max-tablet-width - 1px}) {
        @content;
    }
}

@mixin desktop {
    @media (max-width: #{$desktop-width}) {
        @content;
    }
}

@mixin xldesktop {
    @media (min-width: #{$xldesktop-width}) {
        @content;
    }
}

// end media queries //
//tekststijl voor h2, legend
@mixin subheader($subtitlecolor: $color-subtitle, $fontsize: $fontsize-h2) {
    font-size: $fontsize;
    color: $subtitlecolor;
    font-family: $webfont;
    font-weight: 700;
    line-height: 1.7rem;
}

//tekststijl voor a, tekstlink
@mixin textlink {
    color: $color-base;
    text-decoration: none;
    box-shadow: 0 2px 0 $color-cta !important;
    &:visited {
        color: $color-base;
        text-decoration: none;
    }
    &:hover {
        color: $color-cta;
        text-decoration: none;
    }
    &:active {
        color: $color-cta;
        text-decoration: none;
    }
}

//tekststijl voor primaire button
@mixin button-primary {
    border-radius: $borderradius-buttons;
    cursor: pointer;
    padding: 12px 22px;
    margin: 0;
    margin-right: 25px;
    display: inline-block;
    height: auto;
    text-decoration: none;
    text-align: center;
    background-color: $color-button-primary;
    color: $white;
    font-weight: 700;
    line-height: 1;
    font-size: 1rem;
    box-shadow: none !important;
    transition: all .3s ease-in-out;
    span {
        color: $white;
    }
    &:visited {
        background-color: $color-button-primary;
        color: $white;
    }
    &:hover {
        // @include box_shadow(2);
        background-color: $color-button-primary-hover !important;
        color: $white !important;
        text-decoration: none;
        p,
        a {
            color: $white;
        }
        span {
            color: $white;
        }
    }
    &:active {
        // @include box_shadow(2);
        background-color: $color-button-primary-hover;
        color: $color-text;
    }
    &:focus {
        outline-color: $color-button-primary;
    }
    @include mobile {
        display: block;
        margin-bottom: 15px;
        padding: 12px 15px;
        margin-right: 0;
        width: 100%;
    }
}

//tekststijl voor secundaire button
@mixin button-secondary {
    border-radius: 0;
    cursor: pointer;
    background-color: transparent;
    color: $color-base !important;
    padding: 5px 2px;
    margin: 0;
    margin-right: 15px;
    display: inline-block;
    height: auto;
    text-decoration: none;
    text-align: center;
    line-height: 1;
    font-size: 1rem;
    font-weight: 700;
    box-shadow: 0 2px 0 $color-cta !important;
    &:visited {
        background-color: transparent;
        text-decoration: none;
        color: $color-cta;
    }
    &:hover {
        background-color: transparent !important;
        text-decoration: none;
        color: $color-cta !important;
        font-weight: 700;
    }
    &:active {
        background-color: transparent;
        text-decoration: none;
    }
    &:focus {
        background-color: transparent;
        outline-color: $color-button-secondary;
    }
    @include mobile {
        display: block;
        margin-bottom: 15px;
        padding: 12px 15px;
        margin-right: 0;
        width: 100%;
    }
}

//default divstijl voor een panel
@mixin panel--default {
    padding: 15px;
    background-color: $color-panel;
}

//default divstijl voor een app
@mixin appcontainer--default {
    padding: 35px;
    border: 1px solid $color-border-line;
    margin-bottom: 25px;
    @include mobile {
        padding: 15px;
    }
}

//default divstijl voor de actieve node in een beslisboom
@mixin dg-active--default {
    padding: 25px;
    background-color: $color-dg-active-panel;
    border-radius: $borderradius-panels;
}

//default divstijl voor de geschiedenis nodes in een beslisboom
@mixin dg-history--default {
    padding: 25px;
    background-color: $color-dg-history-panel;
    margin-bottom: 15px;
    border-radius: $borderradius-panels;
}

// clearfix
@mixin clearfix {
    content: "";
    display: table;
    clear: both;
}

// Box shadow
@mixin box_shadow($level) {
    @if $level==1 {
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24) !important;
    }
    @else if $level==2 {
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23) !important;
    }
    @else if $level==3 {
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23) !important;
    }
    @else if $level==4 {
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22) !important;
    }
    @else if $level==5 {
        box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22) !important;
    }
}