.cta {
    &__wrapper {
        display: flex;
        z-index: 19;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-top: -110px;
        margin-bottom: 100px;
        a {
            box-shadow: none !important
        }
    }
    &__btn {
        width: 220px;
        height: 220px;
        background-color: $color-cta;
        border: 8px solid $white;
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 3vw;
        position: relative;
        transition: background-color .3s ease-in-out;
        &:hover {
            background-color: $color-primary;
        }
        img {
            width: 75px;
            margin: 10px 0;
        }
        p {
            color: $white;
            font-weight: bold;
            font-size: 20px;
            line-height: 1.2;
            padding: 0 20px;
            text-align: center;
            min-height: 35px;
            margin-bottom: 0;
        }
        &--link {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
        }
    }
}

@include tablet {
    .cta {
        &__wrapper {
            margin-top: -75px;
            margin-bottom: 60px;
        }
        &__btn {
            width: 150px;
            height: 150px;
            border: 5px solid $white;
            border-radius: 50%;
            img {
                width: 50px;
            }
            p {
                font-size: 18px;
            }
        }
    }
}

@include mobile {
    .cta {
        &__wrapper {
            flex-direction: column;
            z-index: 14;
            align-items: center;
            justify-content: center;
            position: relative;
            margin-top: 30px;
            margin-bottom: 30px;
            padding: 0 15px;
        }
        &__btn {
            width: 100%;
            height: 65px;
            border: 5px solid $white;
            border-radius: 33px;
            flex-direction: row;
            justify-content: flex-start;
            margin: 10px 0;
            padding: 0 20px;
            img {
                width: 35px;
                margin: 5px 25px 5px 5px;
            }
            div {
                display: flex;
                height: 100%;
                align-items: center;
            }
            p {
                font-size: 18px;
                padding: 0;
                text-align: left;
                min-height: 0;
            }
        }
    }
}