// all primary buttons
button,
.button,
[type='submit'],
[type='button'],
button#btnOpslaan,
button.button-primary,
a.btn-primary {
    @include button-primary;
}

// all secondary buttons   
button[id^='btnContinue'],
button#btnAnnuleren,
button.button-secondary,
a.adminButton,
a.btn-secondary {
    @include button-secondary;
}

.button,
[type='submit'],
[type='button'] {
    &[disabled] {
        opacity: 0.6;
        background-color: $color-button-disabled !important;
        box-shadow: none !important;
        color: $color-button-disabled-text !important;
        border: none;
        span {
            color: $color-button-disabled-text !important;
        }
    }
}