.dg_history {
    margin-left: 0;
    margin-bottom: 25px;
    &--Answerslist {
        list-style: none;
        margin-left: 0;
        @include dg-history--default;
        margin-bottom: 15px;
        text-align: center;
    }
    &--QuestionText {
        text-align: center;
        display: block;
        @include subheader($color-dg-history-question, $fontsize-dg-question);
        font-weight: 400;
        margin-top: 0.2rem;
        margin-bottom: 0.5rem;
    }
    &--QuestionAnswers {
        text-align: center;
        margin-left: 0;
        display: flex;
        flex-wrap: wrap;
        position: relative;
        margin: 0 -10px !important;
        li {
            width: calc(33% - 10px);
            text-align: left;
        }
    }
    &--AnswerOption {
        margin: 5px;
        padding: 15px 15px 15px 45px;
        border-radius: 0;
        background: $white;
        font-size: 1rem;
        font-family: $webfont;
        font-weight: 400;
        cursor: pointer;
        position: relative;
        transition: color .3s ease-in-out, background-color .3s ease-in-out;
        &:after {
            content: '';
            background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='14.799' height='26.59'><path d='M.001 25.089l1.5 1.5 13.3-13.293-13.3-13.3-1.5 1.5 11.766 11.8z' fill='rgb(232,152,69)'></path></svg>")no-repeat;
            width: 30px;
            height: 30px;
            background-size: auto 20px;
            background-position: center;
            display: block;
            left: 10px;
            top: 13px;
            position: absolute;
            transition: all .2s ease-in-out;
        }
        &:hover {
            background: $color-primary;
            color: $white;
            &:after {
                background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='14.799' height='26.59'><path d='M.001 25.089l1.5 1.5 13.3-13.293-13.3-13.3-1.5 1.5 11.766 11.8z' fill='rgb(255,255,255)'></path></svg>")no-repeat;
                background-position: center;
                background-size: auto 20px;
            }
        }
        &.selected {
            background: $color-primary;
            color: $white;
            display: inline-block;
            &:after {
                left: 8px;
                top: 15px;
                transform: rotate(90deg);
            }
        }
    }
    &--Answer {
        ul {
            margin-left: 0;
        }
    }
}

@include tablet {
    .dg_history--QuestionAnswers li {
        width: calc(50% - 10px);
    }
}

@include mobile {
    .dg_history--QuestionAnswers li {
        width: 100%;
        margin: 0 0 15px;
    }
    .dg_history--Answer li {
        width: 100%;
        margin: 0 0 15px;
    }
}