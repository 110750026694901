[id*="DGBetalingBevestigen_iDEAL"]{
    h1.headerOne{
        color:$color-base !important; 
        font-size:1rem; 
        font-weight:700;
     }
} 

[id*="DGBetalingBevestigen_iDEAL_Content"]{

    > div{
        display:flex;

        > img{
            order:2; 
            border:1px solid $color-accent-medium; 
            padding:5px; 
            background:$white; 
            border-radius:3px;
        }

        > div{
            padding-top:10px;
            padding-right:15px;
        }
    }
}