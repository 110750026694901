.sidebarcontent {
    a {
        @include textlink;
    }
    a.btn-primary {
        width: 100%;
        color: $white;
    }
    a.btn-secondary {
        width: 100%;
    }
}

@include tablet {
    .sidebarcontent {
        padding: 0 25px;
    }
}

@include mobile {
    .sidebarcontent {
        padding: 0;
        margin: 50px 0;
    }
}