.acfileupload{
      margin-bottom:15px;
    
    span.adminButton{
        @include button-secondary;
		//position:relative;
		//margin-top:0;  
        //margin-bottom:0;
		//display:inline-block;  
		//height: 2.2rem;    
		//line-height:1;  
		//background-color: $white;
		//padding:8px 15px; 
	   //border:1px solid $color-button-secondary;
        //color: $color-button-secondary;
        
        //&:hover{
           // border-color: $color-button-secondary-hover;
           // color:$color-button-secondary-hover;
			//cursor:pointer;
        //}
    }
    
    a {    
        
        span{
            color:$color-link;
        }
        
        &:hover{
            span{
            color:$color-link-hover;
            }
        }
    }
}