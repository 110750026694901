.iris-newsform {
    background: transparent;
}

#newspage {
    .newsdate {
        margin-bottom: 25px;
        span {
            color: $color-cta;
            margin-right: 15px;
            font-weight: bold;
            background: $white;
            padding: 5px 15px;
            font-size: 12px;
            border-radius: 25px;
            margin-bottom: 25px;
        }
    }
    .image-container img {
        object-fit: cover;
        width: 100%;
    }
}

.news-list.detailpage {
    .column:last-child:not(:first-child),
    .columns:last-child:not(:first-child) {
        float: left;
    }
}