.project {
    --image {
        img {
            max-width: none;
            width: 100%;
        }
    }
    &--details {
        padding: 25px 0;
    }
    &--detail {
        border-bottom: 1px solid $color-border-line;
        padding: 10px 0;
    }
    &--detaillabel {
        font-size: 14px;
    }
    &--detailinput {
        color: $color-base;
        font-size: 14px;
        &.date {
            text-transform: capitalize;
        }
    }
    &--short-description {
        margin-top: 25px;
        font-size: 14px;
    }
}

.project-intro {
    margin-bottom: 25px;
    padding-top: 15px;
    transition: all 0s;
    h1 {
        margin-top: 15px;
    }
    &__specs {
        margin-bottom: 25px;
        span {
            color: $color-cta;
            margin-right: 15px;
            font-weight: bold;
            background: $white;
            padding: 5px 15px;
            font-size: 12px;
            border-radius: 25px;
            margin-bottom: 25px;
        }
    }
    &__introtext {
        p {
            font-size: $fontsize-introtext;
            font-weight: 300;
            line-height: 2.2rem;
            color: $color-text-intro;
            margin-bottom: 35px;
        }
    }
    &__title {
        color: $color-project-title;
    }
    &__type {
        color: $color-project-type;
        font-size: 20px;
    }
    &__city {
        color: $color-project-city;
        font-size: 20px;
    }
}

#ProjectPageContent {
    [id^=informationbankcontent] a {
        @include textlink;
    }
}

@include mobile {
    #ProjectPageContent {
        .sidebarcontent {
            padding: 15px;
        }
    }
}