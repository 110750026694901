.direct-links {
    margin-bottom: 80px;
    h2,
    h3,
    h4,
    h5,
    h6 {
        text-align: center;
        margin-bottom: 0;
    }
    .list-menu-open {
        margin-top: 50px;
        display: flex;
        flex-wrap: wrap;
        li {
            width: calc(50% - 10px);
            background: $white;
        }
        li:nth-child(even) {
            margin-left: 20px;
        }
    }
}

@include mobile {
    .direct-links {
        margin-bottom: 30px;
        .list-menu-open {
            li {
                width: 100%;
            }
            li:nth-child(even) {
                margin-left: 0;
            }
        }
    }
}