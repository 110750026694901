.valueText_datum{
	position:relative;
	float:left !important;
	max-width:236px;

	&:after{    
		content:'\e2f1'; 
        position:absolute; 
       	right:30px; 
        top:5px;
        font-size:20px;
        font-family:'icomoon'; 
		color:$color-accent-dark;
	}
}

.valueText_radio label{
	width:100%; 
	padding:3px 0;
}

.valueText_label{
	font-weight:700;
}

.valueText_label{
	border-radius:3px; 
	background:$color-accent-medium;
	padding:5px 10px;
	margin-left:15px;
	float:none !important;
	width:95%; 
	width:calc(100% - 30px);
	box-shadow: inset 0 1px 2px rgba(0,0,0,0.1) !important;
  }
  
  .labelText_label{
	  float:none;
	}