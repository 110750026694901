$header-height: 80px;
@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
    header {
        height: 7rem;
    }
}

header {
    background: $white;
}

.header {
    a {
        text-decoration: none !important;
    }
    .header-container {
        display: flex;
        max-width: 75rem;
        margin: 0 auto;
        padding: 0 0.9375rem;
    }
    .header-logo {
        padding: 20px 0;
        flex: 1;
        .is-logo {
            img {
                height: 60px;
                max-width: none;
                width: auto;
            }
            span {
                display: none;
            }
        }
    }
    .header-menu-list {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        align-items: center;
        height: 100%;
    }
    .header-menu-item-search {
        padding-left: 35px;
    }
    a.header-menu-link {
        display: block;
        font-size: 20px;
        transition: color 0.2s ease-in-out;
        color: $color-link !important;
        padding: 15px 35px;
        margin: 0;
        &:hover {
            color: $color-link-hover !important;
            background: none;
        }
    }
    .header-search {
        display: flex;
        align-items: center;
        margin-left: auto;
    }
}

.desktop-logo img {
    max-height: 4rem;
    width: auto;
}


/* responsive menu */

.overlay {
    background: $color-overlay;
    transition: all 0.2s ease;
    flex: 1;
    .overlay-label {
        width: 100%;
        height: 100%;
        display: block;
    }
}

.mobile-nav {
    background-color: $white;
    @include box_shadow(1);
    position: fixed;
    width: 100%;
    z-index: 15;
    height: $mobile-header-height;
    padding: 0px 0px;
    >div,
    >div a {
        height: 100%;
    }
    .mobile-nav-logo {
        flex: 1;
    }
    .mobile-logo {
        display: block;
        padding: 15px 0 10px 16px;
        img {
            width: 150px;
        }
    }
    nav {
        display: flex;
    }
    .header-button {
        width: $mobile-header-height;
        height: $mobile-header-height;
        text-align: center;
        i {
            color: $white;
            line-height: 1;
            font-size: 2rem;
        }
        p {
            color: $white;
            margin-bottom: 0;
        }
        label {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
    .mobile-nav-search {
        background: $color-cta;
    }
    .mobile-nav-hamburger {
        background: $color-primary;
    }
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        overflow: hidden;
        background-color: transparent;
    }
    .mobile-menu-nav {
        padding: 25px;
        height: calc(100vh - 70px);
        position: absolute;
        right: -100vw;
        margin-left: 16px;
        width: calc(100% - 16px);
        background-color: $color-primary;
        transition: all 0.3s ease;
        opacity: 0;
        visibility: hidden;
        &.open {
            opacity: 1;
            visibility: visible;
            right: 0;
        }
        li {
            padding: 25px;
            a {
                display: block;
                text-decoration: none;
                font-size: 22px;
                text-align: left;
                color: $white;
            }
        }
    }
    .mobile-menu-search {
        position: absolute;
    }
    .mobile-menu-search {
        clear: both;
        z-index: 4;
        height: 0;
        transition: height 0.5s ease;
        display: flex;
        flex-direction: column;
        &.open {
            height: calc(100vh - #{$mobile-header-height});
        }
    }
    .search-icon,
    .menu-icon {
        cursor: pointer;
        user-select: none;
        width: auto;
        height: auto;
    }
    .search-icon:hover::after,
    .menu-icon:hover::after {
        background: transparent;
        box-shadow: none;
    }
    .header-logo {
        padding-left: .9375rem;
        display: inline-block;
    }
}

@include tablet {
    .header .header-search {
        padding: 16px;
    }
    .header a.header-menu-link {
        padding: 15px 18px;
    }
}

@include mobile {
    header {
        height: auto;
    }
}