.wrvInschrijven {
    margin-left: -15px;
    margin-right: -15px;
    legend {
        @include subheader;
    }
    .row.Postcode,
    .row.HO_PostcodeNW {
        width: 50%;
        float: left;
    }
    .row.HuisNr,
    .row.HuisNrToevoeging,
    .row.HO_HuisNrNW,
    .row.HO_HuisNrToevoegingNW {
        width: 25%;
        float: left;
    }
    .title {
        margin-top: 15px;
        h3 {
            margin: 0;
        }
    }
    .horizontal-rule {
        clear: both;
    }
    .actions {
        margin-top: 15px;
        padding-left: 15px;
        padding-right: 15px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
}

@include tablet {
    .wrvInschrijven {
        .row.Postcode,
        .row.HO_PostcodeNW {
            width: 100%;
            float: none;
        }
        .row.HuisNr,
        .row.HuisNrToevoeging,
        .row.HO_HuisNrNW,
        .row.HO_HuisNrToevoegingNW {
            width: 50%;
        }
    }
}

@include mobile {
    .wrvInschrijven {
        .row.Postcode,
        .row.HuisNr,
        .row.HuisNrToevoeging,
        .row.HO_PostcodeNW,
        .row.HO_HuisNrNW,
        .row.HO_HuisNrToevoegingNW {
            width: 100%;
            float: none;
        }
    }
}