.list-menu-open {
    counter-reset: li;
    transition: all .2s ease-in-out;
    margin: 0 auto;
    li {
        list-style: none;
        position: relative;
        background: $color-panel;
        margin-bottom: 20px;
        padding: 0 0 0 30px;
        transition: all .2s ease-in-out;
        &:after {
            content: '';
            background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='14.799' height='26.59'><path d='M.001 25.089l1.5 1.5 13.3-13.293-13.3-13.3-1.5 1.5 11.766 11.8z' fill='rgb(232,152,69)'></path></svg>")no-repeat;
            width: 30px;
            height: 30px;
            background-size: auto 20px;
            background-position: center;
            display: block;
            left: 10px;
            top: 17px;
            position: absolute;
        }
        &:hover,
        &:active,
        &:focus {
            background: $color-primary;
            &:after {
                background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='14.799' height='26.59'><path d='M.001 25.089l1.5 1.5 13.3-13.293-13.3-13.3-1.5 1.5 11.766 11.8z' fill='rgb(255,255,255)'></path></svg>")no-repeat;
                background-position: center;
                background-size: auto 20px;
            }
            a {
                color: $white !important;
            }
        }
        a {
            display: block;
            padding: 16px 16px;
            text-decoration: none !important;
            color: $color-text !important;
            font-size: $fontsize-list-links;
            box-shadow: none !important;
        }
    }
}

@include mobile {
    .list-menu-open {
        margin-bottom: 15px;
    }
}