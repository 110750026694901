.datepicker{
    
    .dow{
        color:$color-primary;
    }
    
    thead{
        border-bottom:1px solid $color-primary;
    }
    
    td.day.active, 
    td span.active{
        background:$color-primary;
    }
    
    td span.month.disabled, 
    td span.year.disabled,
    td.day.disabled{
        color:$color-accent-medium;
    }
}

