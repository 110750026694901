#fotoboek__screen {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: $color-overlay;
    z-index: 9999;
    text-align: center;
    .fotoboek__image {
        height: 100%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        img {
            max-width: 100%;
            max-height: 100%;
            margin: 0 auto;
        }
    }
    .fotoboek__download {
        position: absolute;
        bottom: 20px;
        left: 0;
        right: 0;
    }
}

.fotoboek {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-auto-rows: 150px;
    grid-gap: 5px;
    background: $color-panel;
    padding: 10px;
    img {
        object-fit: cover;
        cursor: pointer;
        width: 100%;
        height: 100%;
        background: $color-overlay;
        &:hover {
            opacity: 0.8;
        }
    }
    &__bt {
        width: 50px;
        height: 50px;
        color: $white;
        text-align: center;
        line-height: 35px;
        cursor: pointer;
        transition: all 0.4s ease;
        font-size: 50px;
        box-sizing: border-box;
        padding-left: 2px;
        position: fixed;
        bottom: 10px;
        border-radius: 50%;
        &:hover {
            background-color: $color-cta;
        }
    }
    &__close {
        top: 20px;
        right: 20px;
        &.siteIcon-close:before {
            font-size: 2rem;
        }
    }
    &__next {
        top: 50%;
        right: 20px;
        background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='14.799' height='26.59'><path d='M.001 25.089l1.5 1.5 13.3-13.293-13.3-13.3-1.5 1.5 11.766 11.8z' fill='rgb(255,255,255)'></path></svg>")no-repeat;
        background-position: center;
    }
    &__prev {
        top: 50%;
        left: 20px;
        background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='14.799' height='26.59'><path d='M.001 25.089l1.5 1.5 13.3-13.293-13.3-13.3-1.5 1.5 11.766 11.8z' fill='rgb(255,255,255)'></path></svg>")no-repeat;
        transform: rotate(180deg);
        background-position: center;
    }
}

@media (min-width: 478px) {
    .fotoboek__box img:nth-child(3n):not(:last-of-type) {
        grid-row-end: span 2;
    }
}

@media (max-width: 768px) {
    .fotoboek__box {
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        grid-auto-rows: 150px;
    }
}

@media (max-width: 450px) {
    .fotoboek__box {
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
        grid-auto-rows: 100px;
    }
}