#Contractselectie_grid{
    
    .link-action{
        
        a.contract{
            width:46%;  
            margin-right:2%; 
            margin-bottom:15px;
            border:1px solid $color-accent-dark; 
            @include box-shadow(1);
            background:$white; 
            text-decoration:none;
            float:left; 
            padding:15px;
            border-radius:3px;
            opacity:0.4;
            
            &.active-contract{
                position:relative;
                border:1px solid $color-secondary; 
                opacity:1;
                
                span{
                    color:$color-text;
                }
                
                &:after{
                    content: "\e206";
                    color:$white; 
                    font-family:'icomoon'; 
                    position:absolute; 
                    font-size:30px; 
                    font-weight:700; 
                    right:10px; 
                    bottom:-10px;
                    border-radius:50%; 
                    width:50px; 
                    height:50px;
                    background:$color-secondary;
                    text-align:center;
                }
            }
            
            span{
                color:$color-text;
            }
        
            &:hover{
                opacity:1;
                border:1px solid $color-secondary; 
        
                span{
                   color:$color-secondary !important;
                }
            }
        }
        
    } 

    .no-link{

        .contract{
            width:46%;  
            margin-right:2%; 
            margin-bottom:15px;           
            background:$color-panel; 
            text-decoration:none;
            float:left; 
            padding:15px;
            border-radius:$borderradius;            
        }
    }
} 

@include mobile{
    #Contractselectie_grid{
    
    .link-action{   
        a.contract{
            width:100%;
            float:none;
            display:block;
            }
        }
    }
    
    .no-link{
        .contract{
            width:100%;
            float:none;
            display:block;
        }
    }
}