.no-padding {
    padding: 0 !important;
}

.padding-top {
    padding-top: 50px;
    @include mobile {
        padding-top: 30px;
    }
}

.padding-bottom {
    padding-bottom: 50px;
    @include mobile {
        padding-bottom: 30px;
    }
}

.no-margin {
    margin: 0 !important;
}

.margin-top {
    margin-top: 50px;
    @include mobile {
        margin-top: 30px;
    }
}

.margin-bottom {
    margin-bottom: 50px;
    @include mobile {
        margin-bottom: 30px;
    }
}

.error {
    color: $color-invalid;
}

::selection {
    color: $white;
    background: $color-primary;
}

:focus {
    outline-color: transparent;
}