.planningHeader {
    @include subheader($color-dg-question, $fontsize-dg-question);
    text-align: center;
}

.dg_planning {
    h3 {
        text-align: center;
        font-size: 18px;
        font-weight: 700;
        color: $color-base;
        margin-top: 15px;
    }
    &__divider {
        height: 50px;
        hr {
            float: none;
        }
    }
    &__date-section,
    &__time-section {
        .date-picker {
            margin-left: 0;
            margin-top: 20px;
            margin-bottom: 30px;
            li {
                width: calc(33% - 10px);
                display: inline-block;
                margin: 5px;
                padding: 15px 15px 15px 45px;
                background: $white;
                position: relative;
                &:after {
                    content: '';
                    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='14.799' height='26.59'><path d='M.001 25.089l1.5 1.5 13.3-13.293-13.3-13.3-1.5 1.5 11.766 11.8z' fill='rgb(232,152,69)'></path></svg>")no-repeat;
                    width: 30px;
                    height: 30px;
                    background-size: auto 20px;
                    background-position: center;
                    display: block;
                    left: 10px;
                    top: 13px;
                    position: absolute;
                    transition: all .2s ease-in-out;
                }
                &:hover,
                &.selected-date,
                &.selected-time {
                    background: $color-primary;
                    color: $white;
                    &:after {
                        background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='14.799' height='26.59'><path d='M.001 25.089l1.5 1.5 13.3-13.293-13.3-13.3-1.5 1.5 11.766 11.8z' fill='rgb(255,255,255)'></path></svg>")no-repeat;
                        background-position: center;
                        background-size: auto 20px;
                    }
                }
            }
        }
    }
    &__conclusion {
        padding-bottom: 25px;
        &-error {
            color: $color-invalid;
        }
        .summary {
            margin-bottom: 15px;
            span {
                color: $color-base;
                font-weight: 700;
            }
        }
    }
    &__skip-section {
        border-top: 1px solid $color-border-line;
        padding: 15px;
        margin-top: 35px;
    }
}

div[id^="R_Element_DGPlannenOnderhoudsverzoek_Confirm"],
div[id^="R_Element_DGPlannenOnderhoudsverzoek_Message"] {
    @include panel--default;
}

@include tablet {
    .dg_planning {
        &__date-section,
        &__time-section {
            .date-picker {
                li {
                    width: calc(50% - 10px);
                }
            }
        }
    }
}

@include mobile {
    .dg_planning {
        &__date-section,
        &__time-section {
            .date-picker {
                li {
                    display: block;
                    width: 100%;
                    margin: 0 0 15px;
                }
            }
        }
    }
}