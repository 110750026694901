[class^="siteIcon-"],
[class*="siteIcon-"] {
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-feature-settings: 'liga';
    font-feature-settings: 'liga';
}
.siteIcon-file-o:before {
    content: "\f016"
}
.siteIcon-twitter-square:before {
    content: "\f081"
}
.siteIcon-facebook-square:before {
    content: "\f082"
}
.siteIcon-linkedin-square:before {
    content: "\f08c"
}
.siteIcon-twitter:before {
    content: "\f099"
}
.siteIcon-facebook:before {
    content: "\f09a"
}
.siteIcon-facebook-f:before {
    content: "\f09a"
}
.siteIcon-linkedin:before {
    content: "\f0e1"
}
.siteIcon-file-text-o:before {
    content: "\f0f6"
}
.siteIcon-youtube-square:before {
    content: "\f166"
}
.siteIcon-youtube:before {
    content: "\f167"
}
.siteIcon-youtube-play:before {
    content: "\f16a"
}
.siteIcon-instagram:before {
    content: "\f16d"
}
.siteIcon-file-pdf:before {
    content: "\f1c1"
}
.siteIcon-file-word:before {
    content: "\f1c2"
}
.siteIcon-file-excel:before {
    content: "\f1c3"
}
.siteIcon-file-powerpoint:before {
    content: "\f1c4"
}
.siteIcon-file-image:before {
    content: "\f1c5"
}
.siteIcon-file-photo:before {
    content: "\f1c5"
}
.siteIcon-file-picture:before {
    content: "\f1c5"
}
.siteIcon-flipvertical:before {
    content: "\e901"
}
.siteIcon-fliphorizontal:before {
    content: "\e902"
}
.siteIcon-new_case:before {
    content: "\e90b"
}
.siteIcon-new_message:before {
    content: "\e90c"
}
.siteIcon-decision-tree:before {
    content: "\e90a"
}
.siteIcon-website:before {
    content: "\e909"
}
.siteIcon-page:before {
    content: "\e908"
}
.siteIcon-donut_large:before {
    content: "\e905"
}
.siteIcon-donut_small:before {
    content: "\e906"
}
.siteIcon-timeline:before {
    content: "\e907"
}
.siteIcon-drag-handle:before {
    content: "\e904"
}
.siteIcon-update:before {
    content: "\e903"
}
.siteIcon-bling:before {
    content: "\e900"
}
.siteIcon-error:before {
    content: "\e000"
}
.siteIcon-error_outline:before {
    content: "\e001"
}
.siteIcon-warning:before {
    content: "\e002"
}
.siteIcon-equalizer:before {
    content: "\e007"
}
.siteIcon-explicit:before {
    content: "\e008"
}
.siteIcon-pause:before {
    content: "\e018"
}
.siteIcon-play_arrow:before {
    content: "\e01b"
}
.siteIcon-stop:before {
    content: "\e02a"
}
.siteIcon-web:before {
    content: "\e034"
}
.siteIcon-call_end:before {
    content: "\e040"
}
.siteIcon-chat:before {
    content: "\e046"
}
.siteIcon-chat_bubble:before {
    content: "\e054"
}
.siteIcon-add:before {
    content: "\e069"
}
.siteIcon-add_circle_outline:before {
    content: "\e06c"
}
.siteIcon-content_copy:before {
    content: "\e071"
}
.siteIcon-content_paste:before {
    content: "\e073"
}
.siteIcon-create:before {
    content: "\e074"
}
.siteIcon-link:before {
    content: "\e07b"
}
.siteIcon-mail:before {
    content: "\e07c"
}
.siteIcon-redo:before {
    content: "\e07e"
}
.siteIcon-remove:before {
    content: "\e07f"
}
.siteIcon-remove_circle_outline:before {
    content: "\e081"
}
.siteIcon-save:before {
    content: "\e085"
}
.siteIcon-send:before {
    content: "\e087"
}
.siteIcon-undo:before {
    content: "\e08a"
}
.siteIcon-access_time:before {
    content: "\e08e"
}
.siteIcon-signal_wifi_off:before {
    content: "\e0bb"
}
.siteIcon-attach_file:before {
    content: "\e0c0"
}
.siteIcon-format_align_left:before {
    content: "\e0d0"
}
.siteIcon-format_size:before {
    content: "\e0df"
}
.siteIcon-insert_chart:before {
    content: "\e0e5"
}
.siteIcon-insert_drive_file:before {
    content: "\e0e7"
}
.siteIcon-mode_edit:before {
    content: "\e0ee"
}
.siteIcon-file_upload:before {
    content: "\e100"
}
.siteIcon-folder:before {
    content: "\e101"
}
.siteIcon-folder_open:before {
    content: "\e102"
}
.siteIcon-desktop_mac:before {
    content: "\e107"
}
.siteIcon-smartphone:before {
    content: "\e127"
}
.siteIcon-tablet_mac:before {
    content: "\e12c"
}
.siteIcon-crop:before {
    content: "\e153"
}
.siteIcon-image:before {
    content: "\e186"
}
.siteIcon-photo_camera:before {
    content: "\e1a4"
}
.siteIcon-picture_as_pdf:before {
    content: "\e1a6"
}
.siteIcon-rotate_left:before {
    content: "\e1aa"
}
.siteIcon-rotate_right:before {
    content: "\e1ab"
}
.siteIcon-local_phone:before {
    content: "\e1e9"
}
.siteIcon-place:before {
    content: "\e1f7"
}
.siteIcon-apps:before {
    content: "\e1ff"
}
.siteIcon-arrow_back:before {
    content: "\e200"
}
.siteIcon-arrow_drop_down:before {
    content: "\e201"
}
.siteIcon-arrow_drop_up:before {
    content: "\e203"
}
.siteIcon-arrow_forward:before {
    content: "\e204"
}
.siteIcon-check:before {
    content: "\e206"
}
.siteIcon-chevron_left:before {
    content: "\e207"
}
.siteIcon-chevron_right:before {
    content: "\e208"
}
.siteIcon-close:before {
    content: "\e209"
}
.siteIcon-expand_less:before {
    content: "\e20a"
}
.siteIcon-expand_more:before {
    content: "\e20b"
}
.siteIcon-menu:before {
    content: "\e20e"
}
.siteIcon-keyboard_control:before {
    content: "\e20f"
}
.siteIcon-more_vert:before {
    content: "\e210"
}
.siteIcon-unfold_more:before {
    content: "\e213"
}
.siteIcon-phone_forwarded:before {
    content: "\e222"
}
.siteIcon-phone_in_talk:before {
    content: "\e223"
}
.siteIcon-phone_missed:before {
    content: "\e225"
}
.siteIcon-phone_paused:before {
    content: "\e226"
}
.siteIcon-sync:before {
    content: "\e22b"
}
.siteIcon-sync_problem:before {
    content: "\e22d"
}
.siteIcon-wifi:before {
    content: "\e242"
}
.siteIcon-group:before {
    content: "\e245"
}
.siteIcon-notifications:before {
    content: "\e24a"
}
.siteIcon-person:before {
    content: "\e253"
}
.siteIcon-person_add:before {
    content: "\e254"
}
.siteIcon-aspect_ratio:before {
    content: "\e270"
}
.siteIcon-autorenew:before {
    content: "\e278"
}
.siteIcon-chrome_reader_mode:before {
    content: "\e282"
}
.siteIcon-code:before {
    content: "\e284"
}
.siteIcon-delete:before {
    content: "\e287"
}
.siteIcon-history:before {
    content: "\e29d"
}
.siteIcon-home:before {
    content: "\e29e"
}
.siteIcon-info:before {
    content: "\e2a2"
}
.siteIcon-info_outline:before {
    content: "\e2a3"
}
.siteIcon-label:before {
    content: "\e2a6"
}
.siteIcon-label_outline:before {
    content: "\e2a7"
}
.siteIcon-list:before {
    content: "\e2aa"
}
.siteIcon-lock:before {
    content: "\e2ab"
}
.siteIcon-open_in_new:before {
    content: "\e2b2"
}
.siteIcon-open_with:before {
    content: "\e2b3"
}
.siteIcon-question_answer:before {
    content: "\e2c3"
}
.siteIcon-search:before {
    content: "\e2ca"
}
.siteIcon-settings:before {
    content: "\e2cb"
}
.siteIcon-today:before {
    content: "\e2f1"
}
.siteIcon-view_list:before {
    content: "\e301"
}
.siteIcon-view_module:before {
    content: "\e302"
}
.siteIcon-view_stream:before {
    content: "\e304"
}
.siteIcon-visibility:before {
    content: "\e306"
}
.siteIcon-visibility_off:before {
    content: "\e307"
}
.siteIcon-work:before {
    content: "\e30b"
}
.siteIcon-play_for_work:before {
    content: "\e317"
}