.news-list {
    padding: 25px 0;
    margin: 0 -10px;
    .column:last-child:not(:first-child),
    .columns:last-child:not(:first-child) {
        float: left;
    }
    a.button-secondary {
        margin-bottom: 15px;
    }
    &__container {
        display: flex;
        a {
            box-shadow: none !important;
        }
    }
    &__item {
        display: flex;
        padding: 0 10px;
        margin-bottom: 20px;
    }
    &__content {
        width: 100% !important;
        overflow: hidden;
        background: $white;
        transition: box-shadow .3s ease-in-out;
    }
    &__featured-image {
        aspect-ratio: 16 / 9;
        min-height: 12vh;
        overflow: hidden;
        background-size: cover;
        transition: opacity .3s ease-in-out;
        img {
            width: 100%;
            max-width: inherit;
        }
        figure {
            margin: 0;
            height: 150px;
            background-size: cover;
        }
    }
    &__intro {
        padding: 15px 0;
        .news-list__date {
            color: $color-newslist-date;
            font-size: $fontsize-newslist-date;
            font-weight: 300;
            margin-bottom: 0.5rem;
        }
        .news-list__title {
            color: $color-newslist-title;
            font-size: $fontsize-newslist-title;
            font-weight: 700;
        }
    }
    &__item:hover,
    &__item:active,
    &__item:focus {
        .news-list__content {
            .news-list__title {
                color: $color-cta;
            }
        }
        .news-list__featured-image {
            opacity: 0.8;
        }
    }
}

.news-list.overview {
    .news-list__container {
        flex-wrap: wrap;
    }
    .news-list__intro {
        padding: 15px 10px;
    }
}

.news-list.detailpage {
    .news-list__intro {
        padding: 15px 10px;
    }
}

@include tablet {
    .news-list {
        &__container {
            display: block;
        }
        &__item {
            margin-bottom: 15px;
            padding-right: 0;
        }
        &__featured-image {
            aspect-ratio: unset;
            min-height: 18vw;
        }
        &__intro {
            padding: 0 15px;
        }
    }
    .news-list.overview {
        .news-list__featured-image {
            min-height: 33vw;
        }
    }
}

@include mobile {
    .news-list {
        &__container {
            display: block;
        }
        &__item {
            display: block;
            margin-bottom: 15px;
        }
        &__featured-image {
            min-height: 45vw;
        }
        &__intro {
            padding: 15px 0;
        }
    }
}