.dg_overlay{
    position:fixed;
    background: $color-overlay;
    top:0; 
    bottom:0; 
    left:0; 
    right:0;
    z-index:20;
}

.dg_modal{
    position:fixed; 
    background:$white;
    top:20vh;
    padding:30px;
    border-radius:3px;
    @include box-shadow(2);
    width:50vw; 
    margin:0 auto;
    left:0; 
    right:0; 
    z-index:21;

    h2{
        margin-top:15px;
    }

    .actions{
        margin:25px 0 15px; 
        padding-top:25px; 
        border-top:1px solid $color-border-line;
    }
}

@include mobile{
    
    .dg_modal{
        top:10vh;
        width:85vw;
        padding:15px;
        text-align:center;
    } 
}