.infoBetalingsregeling{

    div[id^='R_Element_InfoBetalingsregeling_List']{
        
        table tr:hover{
            cursor:pointer;
            background-color:$color-panel;
        }
    }

    div[id^='R_Element_InfoBetalingsregeling_Detail']{
        background:$color-panel; 
        border-radius:3px; 
        padding:5px 15px;
    }
}