.dgSelecterenOpenstaandePosten{

    tbody tr:hover{
        background:$color-accent-medium; 
        cursor:pointer;
    }

    .fa{ 
        width:15px; 
        height:15px; 
        border:1px solid $color-text;
        display:block; 
        border-radius:3px;
        margin:5px;
    }
    
    .fa-check-square-o{
        position:relative;
        
        &:before{
            position:absolute;
            content:'\e206'; 
            font-family:'icomoon'; 
            font-weight:700;
            color:$white; 
            font-size:1.8rem;
            top:-18px; 
            left:-8px;
        }
    }

    div[id^="frmSelecterenOpenstaandePosten"] 
    form > div {
        font-weight:700;
    }
} 

@include mobile{
    .dgSelecterenOpenstaandePosten{
        .responsive-table tbody tr td:first-child:before{
            display:none;
        }
    }
}