.infoContractSamenstelling{
    
    form .labelText{
        margin:0;
        font-style:italic;
    }

    div[id^='R_Element_InfoContractSamenstelling_List']{
        
        thead th a{
            color:$color-text; 
            text-decoration:none; 
            cursor:auto;
            
            &:hover{
                color:$color-text;
            }
        }

        tbody tr{
            cursor:pointer;
            
            &:hover{
                background:$color-accent-dark;
            }
        }

    }


    div[id^='R_Element_InfoContractSamenstelling_Detail']{
        padding:25px; 
        background:$color-accent-medium;
        
        .row{
            padding:10px 0;
        }
        
        h3{
            margin-top:25px;
        }
    }

}

