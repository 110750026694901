.infoOverzichtProjecten{  
    @include appcontainer--default;
    
    &:after{
        content: "";
        display: table;
        clear: both;
    }
    
    h3{
        margin-bottom:15px;
    }
    
    .detail-padding{  
        padding:0px 15px 25px;
        
        @include mobile{
            padding:15px 15px 0px;
        }
    }
    
    &--details {
        padding:0 15px;

        .row{
            border-bottom:1px solid $color-accent-dark;
            padding:10px 0;
        }
    }
   
    div[id^="Online_InfoOverzichtProjecten_List"]{
        table tbody tr:hover{ 
            cursor:pointer; 
            background:$color-accent-dark;
        }        
    } 
    
    div[id^="Online_InfoOverzichtProjecten_Detail"] {
         legend{
             margin:0; 
             padding:0;
        }
    }
   
}