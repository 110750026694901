[class^="icon-"],
[class*=" icon-"] {
    display: none;
}

.dg_questionAnswerIcon,
.dg_questionIcon,
.dg_questionAnswerHistIcon {
    display: none;
}

.dg_answerPanel ul>li.dg_questionAnswerHist .dg_questionAnswerHistText ul>li>span[class*=" icon-"] {
    display: none;
}

// .icon-badkamer,
// .icon-balkon,
// .icon-box,
// .icon-schuur,
// .icon-box,
// .icon-gang,
// .icon-tuin,
// .icon-portaal,
// .icon-ketelruimte,
// .icon-galerij,
// .icon-gevel,
// .icon-gang,
// .icon-kelder,
// .icon-keuken,
// .icon-lift,
// .icon-dak,
// .icon-gang,
// .icon-parkeerplaats,
// .icon-slaapkamer,
// .icon-toilet,
// .icon-trappenhal,
// .icon-woonkamer,
// .icon-zolder,
// div[data-container^="DG/DGGraph_WV"] .icon-elektra
// {
// 	display:block;
//    	font-size:40px;
//     line-height:1;
// 	width:100%;	
// 	}
// .icon-locatie-badkamer,
// .icon-locatie-balkon,
// .icon-locatie-schuur,
// .icon-locatie-box,
// .icon-locatie-gang,
// .icon-locatie-tuin,
// .icon-locatie-portaal,
// .icon-locatie-ketelruimte,
// .icon-locatie-galerij,
// .icon-locatie-gevel,
// .icon-locatie-gang,
// .icon-locatie-kelder,
// .icon-locatie-keuken,
// .icon-locatie-lift,
// .icon-locatie-dak,
// .icon-locatie-gang,
// .icon-locatie-parkeerplaats,
// .icon-locatie-slaapkamer,
// .icon-locatie-toilet,
// .icon-locatie-trappenhal,
// .icon-locatie-woonkamer,
// .icon-locatie-zolder,
// .icon-locatie-recreatieruimte,
// .icon-locatie-elektra
// 	{
// 		display:block;
// 		font-size:40px;
// 		line-height:1;
// 		width:100%;	
// 		}