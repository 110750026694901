div[id^="lstWoningwaardering"]{
        
    >.row{
        border-bottom:2px solid $white;
        padding: 10px 0px; 
        cursor:pointer;
        background: $color-accent-medium;
        
        &:hover{
            background: $color-accent-light;
        }
    }
    
     .appListDark{
         background:$color-accent-light;
    }
}