.online-search-container {
    position: relative;
    .search-input {
        border: none;
        background-color: $color-searchbar-header;
        height: 36px;
        box-shadow: none !important;
        padding: 8px 12px;
        font-size: 16px;
        margin: 0;
    }
    .input-search-icon {
        position: absolute;
        top: 0;
        right: 0;
        width: 40px;
        height: 36px;
        padding-top: 0;
        transition: box-shadow 0.5s ease-in-out;
        -webkit-transition: box-shadow 0.5s ease-in-out;
        a {
            position: relative;
            padding: 0 6px;
            color: $color-search-icon;
            font-size: 25px;
            text-decoration: none;
            &:hover {
                color: $color-primary;
                text-decoration: none;
            }
        }
    }
}

// search in mobile menu
.mobile-menu-search {
    >li {
        border-top: 1px solid $color-cta;
        background: $white;
        padding: 15px;
    }
    .search-container {
        .input-search-icon a {
            background-color: $color-primary;
            color: $white;
            text-indent: 0;
        }
    }
}