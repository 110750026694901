.card-image{
	background-size:auto 100%; 
	background-size:cover; 
	background-repeat: no-repeat;	
} 

.pageheader__image{
	
	.row{
		max-width: 100%; 
		width: 100%;
		margin: 0 auto;
	
		.columns{
			text-align: center;
			padding: 0;

			img{
				max-width: none; 
				width: 100%; 
			}
		}
	}
}

@include desktop{
	.pageheader__image{

		.row{
			max-width: 100%;
			padding: 0;
		}
	}
}
