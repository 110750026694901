.labelText{
    font-size:$fontsize-base;
    margin-top:15px;
    margin-bottom:5px;
	}


.columns.small-12.medium-6.large-4.labelText{ 
    margin-top:0;
    margin-bottom:15px; 
    font-style:italic;
}

@include mobile{
    .columns.small-12.medium-6.large-4.labelText{ 
        margin-top:15px;
        margin-bottom:0; 
    }
}