input,
[type='text'],
[type='password'],
[type='date'],
[type='datetime'],
[type='datetime-local'],
[type='month'],
[type='week'],
[type='email'],
[type='number'],
[type='search'],
[type='tel'],
[type='time'],
[type='url'],
[type='color'],
textarea {
    background-color: $color-input;
    border: none;
    border-bottom: 1px solid $color-primary;
    border-radius: $borderradius !important;
    box-sizing: inherit !important;
    box-shadow: none !important;
    &:focus {
        background-color: $color-input;
        border-bottom: 1px solid $color-cta !important;
        box-shadow: 0 0 5px #cacaca !important;
        transition: border-color 0.25s ease-in-out !important;
    }
}

textarea {
    height: 120px;
}

input:not(.search-input) {
    &.invalid {
        border-bottom: 2px solid $color-invalid;
    }
    &.ng-invalid.ng-touched {
        border-bottom: 2px solid $color-invalid;
    }
}

input.search-input {
    &.ng-invalid.ng-touched,
    &.invalid {
        border: none;
    }
}