ac-tooltip span[word]{
    color:$color-secondary;

     span.tooltip{
        background: $color-secondary;

        &:after{
            border-bottom: 10px solid $color-secondary;
        }
     }
}

a.formtooltip{
    text-decoration:none;
    position:relative;
    
    &:after{
        content: "\e2a3"; 
        font-family:'icomoon';
        line-height:0.2rem;  
    }
    
    &:hover{
        
        span{
            display: inline;
            position: absolute;
            left:-130px;
            right:-120px;
            top:0px;
            width:250px;
            background: $color-secondary;
            text-align:center;
            border:none;
            border-radius:$borderradius;
            color: $white;
            z-index: 999;
            line-height:1.3rem; 
            padding:8px; 
            
                &:after{
	               bottom: 100%;
	               left: 50%;
	               border: solid transparent;
	               content: " ";
	               height: 0;
	               width: 0;
	               position: absolute;
	               pointer-events: none;
	               border-color: rgba(255, 131, 0, 0);
	               border-bottom-color: $color-secondary;
	               border-width: 10px;
                    margin-left: -10px;
            }
        }
    }
}

