div[id^="lstContracten"]{
    
    &:after{
        content: "";  
        display: table;  
        clear: both;
    }
    
    .contract{
        background:$color-accent-medium;
        padding:15px;
        display:block;
        margin-bottom:10px;
        width:45%;
        margin-right:5%; 
        float:left;
        text-decoration:none;
        transition: background 0.3s ease-in-out;
        
        &:hover{
            background:$color-accent-dark;
        }
    }
} 

@include mobile{
    div[id^="lstContracten"]{
        .contract{
            float:none;
            width: 100%;
            margin-right: 0;
        }
    }
}


