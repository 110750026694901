.kb-search {
    min-width: 225px;
    position: relative;
}

.kb-search__container {
    position: relative;
    display: flex;
}

input.kb-search__input {
    border: none;
    border-radius: 0 !important;
    background-color: $color-searchbar-header;
    height: 36px;
    border-bottom: 1px solid $color-searchbar-header;
    flex: 1;
    box-shadow: none !important;
    padding: 8px 12px;
    font-size: 16px;
    margin: 0;
    &:focus {
        border: none;
        border-radius: 0 !important;
        background-color: $color-searchbar-header;
        border-bottom: 1px solid $color-cta;
        box-shadow: none !important;
    }
}

.kb-search__button {
    border-radius: 0;
    height: 36px;
    width: 36px;
    position: absolute;
    right: 0;
    padding: 0;
    margin: 0;
    background-color: transparent;
    svg {
        fill: $color-primary;
    }
    &:hover {
        cursor: pointer;
        background-color: transparent !important;
        svg {
            fill: $color-cta;
        }
    }
}

.kb-search__results {
    min-width: 225px;
    position: absolute;
    margin-top: 2px;
    background-color: $white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.37);
    list-style: none;
    margin: 0;
    padding: 0;
    right: 0;
    z-index: 9999;
}

.kb-search__result,
.kb-search__item {
    margin: 0;
    padding: 0;
}

.kb-search__result>button {
    display: block;
    padding: 0.5rem 1rem;
    margin: 0;
    width: 100%;
    height: auto;
    background-color: $white;
    color: rgba(0, 0, 0, 87) !important;
    font-weight: 500;
    border-radius: 0;
    text-align: left;
    line-height: 1.5;
}

.kb-search__result>button:hover,
.kb-search__result.is-selected button {
    text-decoration: underline;
    background-color: $white !important;
    color: rgba(0, 0, 0, 87) !important;
}

.kb-search__link {
    position: relative;
    display: block;
    padding: 0.5rem 1rem;
    padding-right: 2.5rem;
    height: auto;
    line-height: 1.5;
    font-weight: 300;
    color: $color-text;
    font-size: 1rem;
    padding-left: 25px;
    &:after {
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        width: 2rem;
        top: 14px;
        bottom: 0;
        left: 10px;
        background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='14.799' height='26.59'><path d='M.001 25.089l1.5 1.5 13.3-13.293-13.3-13.3-1.5 1.5 11.766 11.8z' fill='rgb(232,152,69)'></path></svg>")no-repeat;
        background-size: auto 15px;
    }
}

.kb-search__link:hover,
.kb-search__result.is-selected .kb-search__link {
    background-color: $color-accent-light;
    color: $color-base !important;
}

.kb-search__header {
    display: block;
    padding: 0.5rem 1rem;
    font-size: 14px;
    font-weight: bold;
    color: $white;
    background-color: $color-primary;
}

@include tablet {
    header {
        .kb-search {
            min-width: 0;
            margin-top: -18px;
        }
        .header-search {
            .closeIcon {
                display: none;
            }
            .searchIcon {
                display: block;
            }
        }
        input.kb-search__input {
            position: absolute;
            width: 0;
            right: 0;
            padding: 0;
            transition: width .3s ease-in-out;
        }
        .tablet-search {
            background: $color-accent-medium;
            padding: 6px;
            width: 36px;
            height: 36px;
            transition: background .3s ease-in-out;
            svg {
                fill: $color-primary;
                position: absolute;
            }
            &:hover {
                background: $color-primary;
                svg {
                    fill: $white;
                }
            }
        }
        .header-search.open {
            .tablet-search {
                background: $color-primary;
                padding: 7px 8px;
                svg {
                    fill: $white;
                }
            }
            .closeIcon {
                display: block;
            }
            .searchIcon {
                display: none;
            }
            input.kb-search__input {
                width: calc(100vw - 300px);
                padding-left: 10px;
            }
        }
        .kb-search__button {
            display: none;
            background: $color-searchbar-header !important;
            &:hover {
                background: $color-searchbar-header !important;
            }
        }
        .kb-search__results {
            right: -36px;
            top: 36px;
            width: calc(100vw - 263px);
        }
    }
}

.search-on-page {
    .kb-search__results {
        left: 0;
        bottom: 35px;
        max-height: 400px;
        overflow-y: auto;
    }
}

@include mobile {
    .kb-search__results {
        width: 100%;
        margin-top: 10px;
    }
    .kb-search__link {
        line-height: 1.8;
        font-size: 1.2rem;
    }
}