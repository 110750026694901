.breadcrumb {
    list-style: none;
    margin-left: 0;
    margin-top: 15px;
    &__container {
        display: flex;
        justify-content: flex-end;
        .button--back {
            color: $color-text;
            position: relative;
            display: inline;
            margin-left: 20px;
            font-size: 12px;
            padding: 0 15px 15px 10px;
            font-weight: 700;
            &:hover {
                color: $color-cta;
            }
        }
    }
    &--item {
        float: left;
        a {
            color: $color-text;
            font-size: 12px;
            position: relative;
            padding: 0 15px 15px 10px;
            &:hover {
                color: $color-cta;
            }
        }
        &:not(:last-child) {
            a:after {
                content: '';
                color: $color-cta;
                margin-left: 10px;
                position: absolute;
                top: 7px;
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background: $color-cta;
            }
        }
        &:last-child {
            font-weight: 700;
        }
    }
}

@include mobile {
    .breadcrumb {
        &__container {
            justify-content: flex-start;
            margin-bottom: 5px;
        }
        &__back-to-overview {
            margin-bottom: 5px;
        }
        &--item:last-child {
            display: none;
        }
    }
}