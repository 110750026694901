//reset float right voor buttonpositie uit uiSite CSS
.dgstartelement .wrvInschrijven .button:nth-child(1):nth-last-child(1), 
.dgstartelement .wrvInschrijven .button__action:nth-child(1):nth-last-child(1), 
.dgstartelement .wrvInschrijven .button__flat:nth-child(1):nth-last-child(1), 
.dgstartelement .wrvInschrijven .inverted:nth-child(1):nth-last-child(1) 
{
    float: none !important; 
}

.dgstartelement {
	
	input[name^="inpPostcode_"],
	input[name^="inpHuisNr_"], 
	input[name^="inpHuisNrToevoeging_"]{
		width:100%;
	}

	.appContainer{
		margin-left:15px;
		margin-right:15px; 
		width:calc(100% - 30px);
	}
}

.dg_questionAnswerText, 
.dg_questionText, 
.dg_questionAnswerHistText{
	padding-left:0;
}


.dgstartelement{
  padding:0px;
  margin:0;
  max-width:none;
}

.dg_elementExpandedPanel ul li{
	list-style-type:disc;
}