.breaking-news {
    &__holder {
        background: $color-breakingnews-bg;
        z-index: 20;
        h1 {
            color: $color-breakingnews-title;
            text-transform: initial;
            font-size: $fontsize-introtext;
            margin: 0;
            display: inline-block;
        }
        a.button-secondary {
            color: $color-breakingnews-link;
            background-color: transparent;
            font-size: 1rem;
            padding: 3px 7px !important;
            display: inline-block;
        }
    }
    &__content {
        padding: 12px 0;
    }
}