.app-search-results {
    padding: 0;
    background: transparent !important;
    border: none;
    width: 66%;
    margin: 75px auto 0;
    a.app-search-results__link {
        box-shadow: none !important;
    }
    &__header {
        .appTitle {
            font-size: 2.2rem;
            color: $color-primary;
            font-weight: 700;
            line-height: 1.2;
            margin-top: 25px;
            margin-bottom: 15px;
        }
    }
    &__row-count {
        color: $color-base;
        font-weight: 300;
        margin-bottom: 25px;
        font-size: 1.5rem;
    }
    .appEmpty {
        color: $color-cta;
        font-weight: 700;
        margin-bottom: 25px;
        font-size: 1.5rem;
    }
    &__list {
        list-style: none;
        margin-left: 0;
        &-item {
            background: $white;
            padding: 0 25px 15px;
            margin-bottom: 15px;
            &:first-child {
                border-radius: 25px 25px 0 0;
            }
            &:last-child {
                border-radius: 0 0 25px 25px;
            }
        }
    }
    &__link {
        text-decoration: none;
        padding: 25px 0 45px;
        display: block;
        position: relative;
        display: flex;
        flex-direction: column;
        &:before {
            position: absolute;
            content: "Lees verder";
            color: $color-base;
            font-weight: 700;
            bottom: 25px;
            box-shadow: 0 2px 0 $color-cta !important;
        }
        &:hover,
        &:active {
            text-decoration: none;
            color: $color-cta;
            &:before {
                text-decoration: none;
                color: $color-cta;
            }
        }
    }
    &__title {
        color: $color-primary;
        font-weight: 700;
        font-size: $fontsize-h3;
        margin-bottom: 0;
    }
    &__type {
        color: $color-primary;
        font-weight: 700;
        margin-right: 15px;
        background: $color-accent-medium;
        padding: 5px 15px;
        font-size: 12px;
        border-radius: 25px;
        margin-bottom: 10px;
        display: inline;
        align-self: flex-end;
        order: -1;
        width: fit-content;
    }
    &__description {
        margin-top: 10px;
        .highlighted {
            font-weight: 700;
            color: $color-cta;
        }
    }
}

@include tablet {
    .app-search-results {
        width: 100%;
        margin: 50px auto 0;
    }
}

@include mobile {
    .app-search-results {
        width: 100%;
        margin: 25px auto 0;
    }
}