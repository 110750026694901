.signup__login__form{

    &--known-mailadres{
        @include dg-active--default;

        h2{
            color:$color-dg-question !important;
        }

        .input-field .data-row{
            margin-left:0 !important; 
            margin-right:0 !important;
        }
    }

    &--other-mailadres{
        @include appcontainer--default;
        margin-top:25px;
        padding:25px;
    }
}