.card-wrapper {
    margin-bottom: 25px;
    .project-item-card {
        background: $white;
        cursor: pointer;
        &:hover {
            .project-item-img {
                opacity: .8;
            }
            .project-item-title {
                color: $color-cta;
            }
        }
        .project-item-img {
            height: 220px;
            overflow: hidden;
            img {
                position: relative;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        .project-item {
            padding: 15px;
            height: 180px;
            overflow: hidden;
            &-title {
                color: $color-projectlist-title;
                font-size: $fontsize-projectlist-title;
                font-weight: 700;
            }
            &-region {
                color: $color-projectlist-region;
                font-size: $fontsize-projectlist-region;
                font-weight: 300;
            }
            &-type {
                color: $color-projectlist-type;
                font-size: $fontsize-projectlist-type;
                font-weight: 300;
            }
            p {
                margin-bottom: 0;
            }
        }
    }
}

@include mobile {
    .project-list {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    .row.project-item-card-row .pointer {
        padding-left: 5px !important;
        padding-right: 5px !important;
    }
    .card-wrapper {
        margin-bottom: 0px;
        .project-item-card {
            .project-item {
                margin-bottom: 10px;
                height: 180px;
            }
            .project-item-img {
                height: 35vw;
                overflow: hidden;
                img {
                    position: relative;
                    top: 0%;
                    transform: translateY(0%);
                }
            }
        }
    }
}

@include small-mobile {
    .row.project-item-card-row .pointer {
        width: 100%;
    }
    .card-wrapper {
        .project-item-card {
            .project-item {
                height: auto;
            }
            .project-item-img {
                height: 65vw;
            }
        }
    }
}