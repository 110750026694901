.dg_conclusion{
    @include dg-active--default;  
    margin-left: 15px;
    margin-right: 15px;
    width: calc(100% - 30px);

    .appContainer{
        border:0;
        padding:0;
        margin:0;
        width:100%;
    }

    .infocard{
        padding:15px 0;
    }
    
    legend,
    h2,
    h1{
        @include subheader($color-dg-question, $fontsize-dg-question);
        margin-top:0;
    }
    
    h3 {
        @include subheader($color-dg-question, $fontsize-dg-question);
        text-align: center;
    }

    .button {
        margin: 0 auto;
    }

    ul li{
        list-style-type:disc;
    }
    
    .actions{
        margin:15px 0;
    }

    .pagination-section{
        border-top:1px solid $color-border-line; 
        padding-top:25px; 
        text-align:center;
    }
}

.dg_conclusion-history{
    @include dg-history--default; 
    margin-left: 15px;
    margin-right: 15px;
    width: calc(100% - 30px);
    
    .appContainer{
        border:0;
        padding:0;
        margin:0;
    }
    
    legend,
    h2,
    h1{
       @include subheader($color-dg-history-question, $fontsize-dg-question);
       opacity: 0.7;
    }
    
    h3{
        font-size:  $fontsize-h5;
        font-weight:700;
        opacity: 0.7;
    }
    
    .actions{
       display:none;
    }
}
