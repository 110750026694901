footer {
    background-color: $color-footer-bg;
    &.margin-top {
        margin-top: 100px;
    }
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $color-footer-title;
    }
    p {
        color: $white;
        a {
            color: $white !important;
        }
    }
    .add-bird {
        padding: 80px 0 0;
        &::before {
            content: "";
            background: url('/uiCustom/GoedeStede/images/kiekedief-op-wit.png') no-repeat;
            position: absolute;
            width: 80px;
            background-size: 80px;
            height: 42px;
            top: -25px;
            right: -25px;
        }
    }
    .subfooter {
        padding: 0 50px;
    }
    .links {
        border-top: 1px solid rgba($white, .6);
        padding: 30px 0;
        margin-top: 30px;
        opacity: .6;
        ul {
            list-style: none;
            padding-left: 0;
            margin-left: 0;
            display: flex;
            align-items: center;
            width: 100%;
        }
        li:not(.pay-off) {
            margin-right: 30px;
        }
        .pay-off {
            flex: 1;
            text-align: right;
            color: $white;
            font-size: 24px;
            opacity: .6;
        }
        a {
            color: $white;
            opacity: .6;
            &:hover {
                opacity: 1;
            }
        }
    }
    .socials {
        display: flex;
        list-style: none;
        margin-left: 0;
        .btn-primary {
            background-color: $color-cta-footer-button !important;
            color: $color-primary !important;
            transition: all .3s ease-in-out;
            &:hover {
                background-color: $color-cta !important;
                color: $white !important;
            }
        }
    }
    .social {
        span {
            display: none;
        }
        a {
            border-radius: 50%;
            padding: 8px 10px;
            color: $color-socialmedia-icons !important;
            border: 1px solid $color-socialmedia-bg;
            font-size: 16px;
            width: 40px;
            height: 40px;
            display: inline-block;
            text-align: center;
            margin-left: 15px;
            text-decoration: none !important;
            &:hover,
            &:active,
            &:focus {
                text-decoration: none !important;
                color: $color-primary !important;
                background-color: $white !important;
            }
        }
    }
    &.homepage {
        .socials {
            justify-content: flex-end;
        }
        .social {
            margin-top: 15px;
            a {
                margin-top: 25px;
                margin-left: 0;
                margin-right: 15px;
            }
        }
    }
}

#listpage {
    +footer {
        .add-bird {
            &::before {
                background: url('/uiCustom/GoedeStede/images/kiekedief-op-blauw.png') no-repeat;
                background-size: 60px;
                top: -20px;
            }
        }
    }
}

#newspage {
    +footer {
        .add-bird {
            &::before {
                background: url('/uiCustom/GoedeStede/images/kiekedief-op-blauw.png') no-repeat;
                background-size: 60px;
                top: -20px;
            }
        }
    }
}

@media (max-width:1250px) {
    footer {
        .socials {
            margin-left: 50px;
        }
    }
}

@media (max-width:1330px) {
    footer {
        .add-bird {
            &::before {
                width: 60px;
                background-size: 60px;
                top: -20px;
            }
        }
    }
    #listpage {
        +footer {
            .add-bird {
                &::before {
                    right: 25px;
                }
            }
        }
    }
    #newspage {
        +footer {
            .add-bird {
                &::before {
                    right: 25px;
                }
            }
        }
    }
}

@include tablet {
    footer {
        .socials {
            margin-left: 25px;
        }
        .subfooter {
            padding: 0 25px
        }
        .add-bird {
            padding: 50px 15px 15px;
        }
        .links {
            .pay-off {
                font-size: 20px;
            }
        }
        .socials {
            justify-content: center;
        }
    }
}

@include mobile {
    footer {
        .footer.margin-top {
            margin-top: 60px;
        }
        .socials {
            margin-left: 0;
        }
        .social a {
            margin-left: 0;
            margin: 0 10px;
        }
        .subfooter {
            padding: 0 15px
        }
        .add-bird {
            padding: 30px 15px 15px;
            &::before {
                width: 50px;
                background-size: 50px !important;
                top: -16px;
                right: 15px;
            }
        }
        .links {
            ul {
                flex-wrap: wrap;
                justify-content: center;
            }
            li:not(.pay-off) {
                margin: 0 5vw;
            }
            .pay-off {
                font-size: 20px;
                flex: none;
                text-align: center;
                width: 100%;
                margin: 25px 0 0;
            }
        }
        .socials {
            justify-content: center;
            flex-wrap: wrap;
            margin-bottom: 0;
            li {
                width: 100%;
                text-align: center;
                padding: 25px 0 15px;
            }
            .btn-primary {
                display: inline;
            }
        }
    }
}