.infoHuuropbouwContract{
    display:flex; 
    flex-direction:column;
    
    .hoverbuttons{
        order:2;
        border-top:1px solid $color-border-line;   
        padding: 15px 0;    
        margin-top: 35px !important; 
        font-size:$fontsize-base;
           
        a{
            font-size:$fontsize-base;
            text-transform:lowercase;
        }
    }
    
    div[id^=R_Element_InfoHuuropbouwContract_Periode]{    
        border-bottom: 1px solid $color-border-line;
        padding: 10px 0px 15px;
        margin-bottom: 15px !important;
        color: $color-text;
        font-weight: 700;
    }
}