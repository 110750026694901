.herobanner {
    margin: -50px 3vw 0;
    border-radius: 50px 50px 0 0;
    overflow: hidden;
    z-index: 18;
    position: relative;
    &__imagecontainer {
        height: 65vh;
        img {
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 100%;
        }
    }
}

@include tablet {
    .herobanner {
        margin: -30px 15px 0;
        border-radius: 30px 30px 0 0;
        &__imagecontainer {
            height: 30vh;
            overflow: hidden;
        }
    }
}

@include mobile {
    .herobanner {
        border-radius: 0;
        z-index: 13;
        margin: 0;
        height: auto;
        &__imagecontainer {
            height: auto
        }
    }
}