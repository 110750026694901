select {
    border: none;
    border-bottom: 1px solid $color-primary;
    color: $color-base;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: none;
    background-color: $color-accent-medium;
}

.custom-select {
    position: relative;
    &::before {
        content: '';
        right: 15px;
        top: 15px;
        transform: rotate(90deg);
        width: 30px;
        height: 30px;
        position: absolute;
        display: block;
        background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='14.799' height='26.59'><path d='M.001 25.089l1.5 1.5 13.3-13.293-13.3-13.3-1.5 1.5 11.766 11.8z' fill='rgb(232,152,69)'></path></svg>") no-repeat;
        background-size: 10px auto;
    }
}