// loader spinner from IRIS
md-progress-circular {
    path {
        stroke: $color-loaderspinner;
    }
}

#loader-bar {
    -webkit-animation: pulse-loader 1000ms ease-in-out 700ms infinite;
    -moz-animation: pulse-loader 1000ms ease-in-out 700ms infinite;
    animation: pulse-loader 1000ms ease-in-out 700ms infinite;
    height: 5px;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10000;
    span {
        -webkit-animation: loading 500ms ease-in-out;
        -moz-animation: loading 500ms ease-in-out;
        animation: loading 500ms ease-in-out;
        -webkit-transform: translateX(-30%);
        -moz-transform: translateX(-30%);
        -ms-transform: translateX(-30%);
        -o-transform: translateX(-30%);
        transform: translateX(-30%);
        background-color: $color-loaderbar;
        opacity: 0.8;
        border-radius: 1px;
        display: block;
        height: 100%;
        position: absolute;
        top: 0;
        width: 100%;
    }
}

#loader-bar.done span {
    -webkit-animation: loading-done 300ms ease-in;
    -moz-animation: loading-done 300ms ease-in;
    animation: loading-done 300ms ease-in;
    opacity: 0;
}

.online-loader {
    &__overlay {
        z-index: 99999;
        position: fixed;
        background-color: $color-loaderoverlay;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    &__wrapper {
        margin: 30vh auto 0;
        background: $color-loaderwrapper;
        padding: 15px;
        width: 200px;
        border-radius: $borderradius;
        .loading-spinner {
            border: 12px solid $color-loaderspinner;
            border-top: 12px solid RGBA(255, 255, 255, 0.6);
            ;
            border-radius: 50%;
            margin: 15px auto 0;
            width: 120px;
            height: 120px;
            animation: spin 2s linear infinite;
        }
        .loading-text {
            padding-top: 15px;
            p {
                font-weight: bold;
                color: $color-loadertext;
            }
        }
    }
}

.pagination-section .pagination-loader {
    display: none;
}