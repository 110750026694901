:root {
    font-size: $fontsize-base;
    @include mobile {
        font-size: $fontsize-base-mobile;
    }
}

body,
p,
span {
    font-family: $webfont;
    font-weight: 400;
    color: $color-text;
}

h1 {
    font-size: $fontsize-h1;
    color: $color-title;
    font-family: $webfont;
    font-weight: 700;
    line-height: 1.2;
    margin-top: 25px;
    margin-bottom: 1.4rem;
}

legend,
h2 {
    @include subheader;
    font-family: $webfont;
    font-weight: 700;
}

h3 {
    font-size: $fontsize-h3;
    color: $color-base;
    font-family: $webfont;
    font-weight: 700;
}

h4 {
    font-size: $fontsize-h4;
    font-family: $webfont;
    font-weight: 700;
    color: $color-title;
}

h5 {
    font-size: $fontsize-h5;
    font-family: $webfont;
    font-weight: 700;
    color: $color-title;
}

h6 {
    font-size: $fontsize-h6;
    font-family: $webfont;
    font-weight: 700;
    color: $color-title;
}

.intro {
    font-size: $fontsize-introtext;
    font-family: $webfont;
    font-weight: 300;
    line-height: 2.2rem;
    color: $black;
    margin-bottom: 35px;
    padding-left: 0;
}

[id^=PageContent] a {
    @include textlink;
}

a i {
    text-decoration: none;
}

i {
    font-size: 12pt;
    font-weight: 300;
    font-style: italic
}

.collapse__title {
    font-size: $fontsize-h3;
    color: $color-base;
    font-weight: 700;
    margin-bottom: 5px;
    margin-top: 15px;
    &:hover {
        color: $color-cta;
    }
    span {
        top: -2px;
        &:before {
            color: $color-accent-dark;
        }
    }
}

.collapse__container {
    border-bottom: 1px solid $color-cta;
    margin-bottom: 25px;
}

.error {
    color: $color-invalid;
}

.blockquote,
q,
blockquote {
    font-size: $fontsize-streamer;
    font-weight: 700;
    color: $color-primary;
    margin-left: 4rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.alertblok {
    border: 2px solid $color-alert;
    background: $color-alert;
    padding: 15px 15px 15px 65px;
    margin-bottom: 25px;
    margin-top: 15px;
    position: relative;
    border-radius: 3px;
    transition: background 0.3s ease-in-out;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $white;
    }
    &.columns {
        margin-left: 15px;
        margin-right: 15px;
        width: calc(100% - 30px);
        p {
            margin-bottom: 0;
        }
    }
    &:before {
        content: '\f5d6';
        top: 3px;
        left: 10px;
        font-family: material-icons;
        position: absolute;
        font-size: 2rem;
        color: $white;
    }
    h2 {
        color: $white;
    }
    a:not(.btn-primary) {
        color: $white;
        text-decoration: none;
        font-weight: 700;
        position: relative;
        display: block;
        margin-bottom: 0;
        &:before {
            content: '\f142';
            top: 0;
            right: 5px;
            font-size: 1.5rem;
            line-height: 1;
            font-family: material-icons;
            position: absolute;
        }
    }
    &:not(.message):hover {
        background: rgba($color-alert, 0.7);
        cursor: pointer;
    }
    &.message {
        background: $white;
        padding: 25px 35px 35px 65px;
        &:before {
            color: $color-alert;
            top: 15px;
            left: 15px;
        }
        h2 {
            color: $color-alert;
        }
    }
}

@include mobile {
    .blockquote,
    q,
    blockquote {
        margin-left: 2rem;
    }
}