.panel {
    @include panel--default;
    .list-menu-open li:hover {
        background: $white;
    }
}

#PageContent {
    .panel {
        width: calc(100% - 30px);
        margin-left: 15px;
        margin-right: 15px;
    }
}