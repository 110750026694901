// SETTINGS 
// -------------------------------------------------------------------------
// TABLE OF CONTENT
// 01 Client color palette
// 02 General colors
// 03 Typography
//  - 03.a Textsizes
//  - 03.b Textcolors
// 04 Buttons
// 05 Images
// 06 Forms
// 07 DG
// 08 Lists
// 09 Layout
//  - 09.a Header
//  - 09.b Footer
//  - 09.c Herobanner homepage
//  - 09.d News
//  - 09.e Infobank
//  - 09.f Search
//  - 09.g Loader
//  - 09.h Stappenplan
//  - 09.i Orbit slider
//  - 09.j Slideshow app
// 10 Measurements / sizes
//  - 10.a Gapsizes
//  - 10.b Borders
//  - 10.c Screensizes
///////////////////////////////////////////////////////////////////////
$clientname: "goedestede";
// -------------------------------------------------------------------------
// 01 - CLIENT COLOR PALETTE
// -------------------------------------------------------------------------
$black: #212121; // was: #424242
$white: #fff;
//$grey-light: #ecf0f1;
//$grey-medium: #d2d6d9;
//$grey-dark: #707070;
//$cyan: #10A8CD;
//$cyan-light: #6ccbe0;
////$blue: #0A86F4; // Secondary
//$blue-dark: #0363B7;
//$green-light: #F3F6ED;
//$green: #9BC34F; // Primary
//$green-medium: #81A343;
//$green-dark: #293616;
$red: #D50000;
$blue:#213e73; // #1B3767; //primary
$blue-light: #E6EFFF;
$orange: #e89845;
$black: #222222;
$grey: #666666;
///////////////////////////////////////////////////////////////////////
// -------------------------------------------------------------------------
// 02 - GENERAL COLORS 
// -------------------------------------------------------------------------
$color-base: $black;
$color-accent-medium: $blue-light;
$color-accent-light: rgba($blue-light, 0.7);
$color-accent-dark: $grey;
$color-primary: $blue;
//$color-primary-light: $green-light;
//$color-primary-dark: $green-dark;
$color-secondary: $black;
//$color-secondary-light: $cyan;
//$color-secondary-dark: $blue-dark;
$color-cta: $orange;
$color-cta-hover: $blue;
$color-invalid: $red;
$color-success: #059b14;
$color-alert: $color-cta;
$color-bg: $white;
$color-panel: $color-accent-light;
$color-overlay: rgba(0, 0, 0, 0.7);
///////////////////////////////////////////////////////////////////////
// -------------------------------------------------------------------------
// 03 - TYPOGRAPHY 
// -------------------------------------------------------------------------
$fontpath: "../../fonts";
$webfont: 'Noto Sans JP',
Helvetica,
Arial,
sans-serif;
// -------------------------------------------------------------------------
// 03.a - Textsizes 
// -------------------------------------------------------------------------
$fontsize-base: 16px;
$fontsize-base-mobile: 14px;
$fontsize-h1: 2.625rem; // 42px
$fontsize-h2: 1.5rem; // 24px
$fontsize-h3: 1.25rem; // 20px
$fontsize-h4: 1.125rem; // 18px
$fontsize-h5: 1rem; // 16px
$fontsize-h6: 1rem; // 16px
$fontsize-introtext: 1.25rem; // 20px
$fontsize-streamer: 1.375rem; // 22px
$fontsize-list-links: 1.125rem; // 18px
$fontsize-breadcrumb: 0.8125rem; // 13px
$fontsize-dg-question: 1.25rem; // 20px
// -------------------------------------------------------------------------
// 03.a - Textcolors
// -------------------------------------------------------------------------
$color-text: $grey;
$color-title: $color-primary;
$color-subtitle: $color-primary;
$color-text-intro: $color-secondary;
$color-streamer: $color-primary;
$color-link: $color-primary;
$color-link-hover: $color-cta;
///////////////////////////////////////////////////////////////////////
// -------------------------------------------------------------------------
// 04 - BUTTONS
// -------------------------------------------------------------------------
$color-button-primary: $color-cta;
$color-button-primary-hover: $color-cta-hover;
$color-button-secondary: $color-primary;
$color-button-secondary-hover: $color-secondary;
$color-button-disabled: $color-accent-dark;
$color-button-disabled-text: $color-accent-light;
$borderradius-buttons: 25px;
$borderradius-panels: 0;
// -------------------------------------------------------------------------
// 05 - IMAGES
// -------------------------------------------------------------------------
$imgpath: "../../images";
$irisdrivepath: "/publishedMedia";
///////////////////////////////////////////////////////////////////////
// -------------------------------------------------------------------------
// 06 - FORMS
// -------------------------------------------------------------------------
$color-input: $white;
$color-label: $color-base;
$color-input-active: $color-secondary;
///////////////////////////////////////////////////////////////////////
// -------------------------------------------------------------------------
// 07 - DG
// -------------------------------------------------------------------------
$color-dg-question: $color-primary;
$color-dg-history-question: $color-accent-dark;
$color-dg-active-panel: $color-accent-light;
$color-dg-history-panel: $color-accent-light;
///////////////////////////////////////////////////////////////////////
// -------------------------------------------------------------------------
// 08 - LISTS
// -------------------------------------------------------------------------
$color-listitem-title: $color-secondary;
$color-listitem-subtitle: $color-accent-dark;
$fontsize-listitem-title: $fontsize-h6;
$fontsize-listitem-subtitle: $fontsize-h6;
///////////////////////////////////////////////////////////////////////
// -------------------------------------------------------------------------
// 09 - LAYOUT
// -------------------------------------------------------------------------
// -------------------------------------------------------------------------
// 09.a - Header
// -------------------------------------------------------------------------
$color-header-bg: $color-bg;
$color-header-link: $color-link;
$color-header-link-hover: $color-link-hover;
$color-header-icon: $color-primary;
$mobile-header-height: 70px;
// -------------------------------------------------------------------------
// 09.b - Footer
// -------------------------------------------------------------------------
$color-footer-bg: $color-primary;
$color-footer-title: $white;
$color-footer-text: $white;
$color-footer-link: $white;
$color-cta-footer-button: $white;
$color-socialmedia-bg: $white;
$color-socialmedia-icons: $white;
// -------------------------------------------------------------------------
// 09.c - Herobanner homepage
// -------------------------------------------------------------------------
$color-hero-title: $white;
$color-hero-title-mobile: $color-text;
$color-hero-text: $white;
$color-hero-button: $color-cta;
$color-hero-button-hover: $color-cta-hover;
// -------------------------------------------------------------------------
// 09.d - News
// -------------------------------------------------------------------------
$color-newslist-title: $color-listitem-title;
$color-newslist-date: $color-listitem-subtitle;
$fontsize-newslist-title: $fontsize-listitem-title;
$fontsize-newslist-date: $fontsize-listitem-subtitle;
$color-news-date: $color-accent-dark;
$color-news-title: $color-secondary;
$color-breakingnews-bg: $color-cta;
$color-breakingnews-title: $white;
$color-breakingnews-link: $white;
// -------------------------------------------------------------------------
// 09.e - Infobank
// -------------------------------------------------------------------------
$color-project-title: $color-title;
$color-project-type: $color-primary;
$color-project-city: $color-primary;
$color-projectlist-title: $color-listitem-title;
$color-projectlist-type: $color-listitem-subtitle;
$color-projectlist-region: $color-listitem-subtitle;
$fontsize-projectlist-title: $fontsize-listitem-title;
$fontsize-projectlist-type: $fontsize-listitem-subtitle;
$fontsize-projectlist-region: $fontsize-listitem-subtitle;
// -------------------------------------------------------------------------
// 09.f - Search
// -------------------------------------------------------------------------
$color-search-icon: $black;
$color-searchbar-header: $color-accent-medium;
$color-searchbar-footer: $white;
$color-searchresult-label: $color-primary;
// -------------------------------------------------------------------------
// 09.g - Loader 
// -------------------------------------------------------------------------
$color-loaderbar: $color-cta;
$color-loaderspinner: $color-primary;
$color-loadertext: $color-base;
$color-loaderoverlay: $color-overlay;
$color-loaderwrapper: rgba(255, 255, 255, .8);
// -------------------------------------------------------------------------
// 09.h - Stappenplan 
// -------------------------------------------------------------------------
$color-step-number: $color-cta;
$color-step-numberbg: $white;
$color-step-borderline: $color-cta;
// -------------------------------------------------------------------------
// 09.i - Orbit Slider
// -------------------------------------------------------------------------
$slider-color-overlay: $color-overlay;
$slider-height: 400px;
$slider-arrows-height: 50px;
$slider-arrows-color: $white;
$slider-arrows-fontsize: 40px;
$slider-text-color: $white;
$slider-text-fontsize: $fontsize-base;
$slider-text-boxheight: 42px;
// -------------------------------------------------------------------------
// 09.j - Slide show app
// -------------------------------------------------------------------------
$slideshow-height: 400px;
///////////////////////////////////////////////////////////////////////
// -------------------------------------------------------------------------
// 10 - MEASUREMENTS / SIZES
// -------------------------------------------------------------------------
// 10.a - Gapsizes
// -------------------------------------------------------------------------
$gap-small: 15px;
$gap-normal: 30px;
$gap-large: 60px;
// -------------------------------------------------------------------------
// 10.b - Borders
// -------------------------------------------------------------------------
$borderradius: 0;
$color-border-line: $color-accent-medium;
///////////////////////////////////////////////////////////////////////
// -------------------------------------------------------------------------
// 10.c - screensizes
// -------------------------------------------------------------------------
$max-mobile-width: 640px;
$max-tablet-width: 1024px;
$desktop-width: 1300px;
$xldesktop-width: 1500px;
///////////////////////////////////////////////////////////////////////