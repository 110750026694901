.IBAN{
    margin-left:15px;
    margin-right:15px;
    width:calc(100% - 30px);

    &--disabled{
	   margin-bottom:15px;
        font-weight:700;
    }
    
    &--last5{
	   border:2px solid $color-primary;
        padding:5px 5px 5px 1px;
        margin-left:1px; 
        border-radius:5px;
    }

}

