.chosen-container-single {
    .chosen-search {
        display: none;
    }
    .chosen-single {
        padding: 5px 10px;
        height: 35px;
        font-size: $fontsize-base;
        border-radius: $borderradius;
        border: 1px solid $color-border-line !important;
        background: $color-input !important;
        box-sizing: inherit !important;
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1) !important;
        abbr {
            background: inherit;
            background-image: inherit !important;
            border-bottom: none;
            width: 20px;
            height: 20px;
            &:after {
                position: absolute;
                content: '\e209';
                font-size: 1.2rem;
                font-family: 'icomoon' !important;
                top: 0px;
                right: -15px;
                font-weight: bold;
            }
        }
    }
    .chosen-default span {
        position: relative;
        background: none;
        width: 100%;
        &:after {
            position: absolute;
            content: "\e20b";
            font-size: 1.2rem;
            font-family: 'icomoon' !important;
            color: $color-accent-medium;
            top: 0px;
            right: 0px;
            font-weight: bold;
        }
    }
}

.chosen-container-active {
    .chosen-single {
        border: 1px solid $color-input-active !important;
        background: $color-input !important;
    }
}

.chosen-container {
    .chosen-drop {
        border-bottom-left-radius: $borderradius;
        border-bottom-right-radius: $borderradius;
        background: $color-input !important;
        font-weight: normal;
        border-color: $color-input-active !important;
    }
    .chosen-results {
        padding: 0;
        margin: 0 0 5px 0;
        li {
            padding: 10px;
            font-size: 1rem;
            &.active-result.highlighted {
                background: $color-input-active;
                color: $white;
            }
        }
    }
}

.chosen-container.chosen-container-single.chosen-container-active.chosen-with-drop .chosen-single {
    background: $white;
    box-shadow: none;
}