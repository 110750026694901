.ac-input__suggest{
    @include panel--default;
    padding:15px;
    display:block; 
    text-align:center;
    position:relative;
    
    &:before{
        content:'Selecteer een bank:';
        font-weight:700; 
        position:relative;
        display:block;
        margin-bottom:5px;
    } 

    a{
        display:inline-block; 
        border:1px solid $color-primary; 
        border-radius:25px; 
        margin:5px;
        padding:8px 15px; 
        color:$color-primary;
        text-decoration:none;
    
        &:hover{
            color:$color-cta;
            border:1px solid $color-cta; 
        }
    }
}