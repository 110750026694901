.mijngegevens{
    text-align:right;
    
    &__holder{
        background:$color-accent-light;
        padding:5px 1rem;
        border-bottom:1px solid $color-border-line;
        font-size:14px;
        z-index:20;
    }
    
    &__username{
        color:$color-text !important;
        font-weight:bold;
        text-decoration: none;
        
        &:hover{
            color:$color-link-hover;
        }
    }
    
    &__btn--uitlog{
        color:$color-accent-dark;
        
        &:hover{
            color:$color-link-hover;
        }
    }
}

@include mobile{
    .mijngegevens{

        &__username{
            padding:0 15px;
        }

        &__btn--uitlog{
            padding:0 15px;
        }
    }
}