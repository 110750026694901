.attachment-for-case{
    
    &__wrapper{
        margin-bottom:15px;
    }

    &__list {
        margin-left:0;
        list-style-type: none !important;
    }

    &__file-card{
        > div{
            display:inline-block;
            padding-right:15px;
        }
    }
}