.dg_question {
    border-bottom: none;
    .dg_questionText {
        text-align: center;
        display: block;
        @include subheader($color-dg-question, $fontsize-dg-question);
        margin: 0 0 15px 0;
    }
}

#dg_question div[data-container^="DG/DGGraph_"] {
    @include dg-active--default;
}

.dg_questionAnswerText,
.dg_questionAnswerHistText {
    font-size: 1rem;
    color: $color-accent-dark;
    transition: color .3s ease-in-out;
}

.dg_questionAnswers {
    text-align: center;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px !important;
    li {
        width: calc(33% - 10px);
        text-align: left;
        position: relative;
        display: block;
        &:after {
            content: '';
            background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='14.799' height='26.59'><path d='M.001 25.089l1.5 1.5 13.3-13.293-13.3-13.3-1.5 1.5 11.766 11.8z' fill='rgb(232,152,69)'></path></svg>")no-repeat;
            width: 30px;
            height: 30px;
            background-size: auto 20px;
            background-position: center;
            display: block;
            left: 10px;
            top: 13px;
            position: absolute;
            transition: all .2s ease-in-out;
        }
        &:hover {
            background: $color-primary;
            .dg_questionAnswerText {
                color: $white;
            }
            &:after {
                background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='14.799' height='26.59'><path d='M.001 25.089l1.5 1.5 13.3-13.293-13.3-13.3-1.5 1.5 11.766 11.8z' fill='rgb(255,255,255)'></path></svg>")no-repeat;
                background-position: center;
                background-size: auto 20px;
            }
        }
    }
}

.dg_questionAnswer {
    margin: 5px;
    padding: 15px 15px 15px 45px;
    border-radius: 0;
    background-color: $white;
    transition: all .3s ease-in-out;
}

@include tablet {
    .dg_questionAnswers li {
        width: calc(50% - 10px);
    }
}

@include mobile {
    .dg_questionAnswers li {
        width: 100%;
        margin: 0 0 15px;
    }
    .dg_questionText,
    .dg_questionAnswerText {
        display: block;
    }
}