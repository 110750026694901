input[type=radio], 
input[type=checkbox]{
    visibility: hidden;    
    margin-bottom: 0;    
    width: 0;    
    position: absolute;

    + label{
        display: inline-block;    
        vertical-align: baseline;
        margin-left: 0;    
        margin-right: 25px; 
        margin-bottom: 0;
        position:relative;
        padding-left:30px;
        cursor:pointer;

        &:after{    
            position: absolute;    
            content: '';    
            width: 18px;   
            height: 18px;    
            background: $color-input;    
            border:1px solid $color-border-line; 
            top: 4px;    
            left: 0;    
            transition: box-shadow 100ms ease-out;    
            color: $color-text;
        } 
    }
}

[type='checkbox'] + label:after{      
    border-radius: $borderradius;       
    }
    
[type='radio'] + label:after{      
    border-radius: 50%;       
    }     

input[type=radio]:checked + label:after, 
input[type=checkbox]:checked + label:after{ 
    box-shadow:inset 0px 0px 0px 3px rgba(255, 255, 255, 1); 
    background:$color-cta;
}

.labelText_checkbox{
    padding-left:45px;
}

.valueText_checkbox{
    margin-top:-50px;
}

.valueText_radio label{
    width:100%;
   }

.panel,
.dg_conclusion,
.dg_conclusion-history{

    input[type=radio], 
    input[type=checkbox]{

        + label{

            &:after{ 
                background:$white;
            }
        }
    }

    input[type=radio]:checked, 
    input[type=checkbox]:checked{

        + label{

            &:after{ 
                background:$color-cta;
            }
        }
    }
}